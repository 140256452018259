import React, { useEffect, useState, useRef } from "react";
import Container from '@mui/material/Container';
import { Modal, Box, FormControlLabel, Checkbox, Menu, TablePagination, } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CardContent } from "@material-ui/core";
import { getMachineList, downloadDemoManualCSVFile, uploadManualCSVFile, getCSVFileHistory, deleteCSVFileHistory } from "./service";
import { toast, ToastContainer } from "react-toastify";
import { images } from "../../config/images";
import Loader from "../../components/Loader/Loader";
import "./UploadData.css";

const UploadData = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [machineList, setmachineList] = useState([]);
    const [checkedMachines, setCheckedMachines] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedMachineIds, setSelectedMachineIds] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fileHistory, setFileHistory] = useState([]);
    const [counHistoryData, setCounHistoryData] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [configrationModal, setConfirmationModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedUids, setSelectedUids] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const handleFileUpload = (event) => {
        const file = (event.target.files[0]);
        setSelectedFile(file);
        console.log("selected file", file);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpen = () => setIsModalOpen(true);
    const handleCloseMenu = () => setAnchorEl(null);

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
        });
    };

    const notifyError = (message) =>
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
        });

    const handleCheck = (e, id) => {
        const isChecked = e.target.checked;
        setCheckedMachines((prevCheckedMachines) => {
            const updatedCheckedMachines = { ...prevCheckedMachines, [id]: isChecked };
            // Update selectedMachineIds array based on the current checkbox state
            const newSelectedMachineIds = Object.keys(updatedCheckedMachines)
                .filter((key) => updatedCheckedMachines[key]) // Only keep checked machines
                .map((key) => parseInt(key, 10)); // Convert keys (strings) to integers
            const newSelectedUids = newSelectedMachineIds.map((machineId) => idToUidMap[machineId]);
            setSelectedMachineIds(newSelectedMachineIds);
            setSelectedUids(newSelectedUids);
            console.log("newSelectedMachineIds", newSelectedMachineIds);
            console.log("newSelectedUids", newSelectedUids);
            return updatedCheckedMachines;
        });
    };

    const idToUidMap = machineList.reduce((map, machine) => {
        map[machine.id] = machine.uid;
        return map;
    }, {});

    //GET Machine List
    const getMachineData = async () => {
        try {
            const response = await getMachineList();
            if (response.data.success == true) {
                setmachineList(response.data.payload);
                console.log("responseMachine", response.data.payload);
            }
        } catch (error) {
            console.log("Error", error);
        }
    };

    //GET CSV File History
    const getFileHistory = async () => {
        setLoading(true);
        try {
            const response = await getCSVFileHistory(page, rowsPerPage);
            if (response) {
                setLoading(false);
                console.log("fileHistory", response);
                setFileHistory(response.data.payload.results);
                setCounHistoryData(response.data.payload.count);
            }
        } catch (error) {
            setLoading(false);
            console.log("Error", error);
        }
    }

    //Download CSV File
    const handleDownload = async () => {
        try {
            const response = await downloadDemoManualCSVFile();
            if (response.data.success) {
                const fileUrl = response.data.payload;
                console.log("responseFile", response.data.payload);
                //file download
                const link = document.createElement('a');
                link.href = fileUrl; // Set href to the URL from payload
                link.download = fileUrl.split('/').pop(); // Optional: Set the filename
                link.click(); // Trigger the download
                notify(response.data.message);
            }
        } catch (error) {
            console.log("Error", error);
        }
    };

    //Upload CSV File
    // const handleUploadCSVFile = async () => {
    //     if (!selectedFile || selectedMachineIds.length === 0) {
    //         console.log("Please select a file and machine.");
    //         notifyError("Please select a file and machine");
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.append('csv_file', selectedFile); // Attach the selected file

    //     // Append each machine ID individually
    //     // selectedMachineIds.forEach(id => {
    //     //     formData.append('machine_ids', id);
    //     // });
    //     formData.append('machine_ids', JSON.stringify(selectedMachineIds));
    //     try {
    //         const response = await uploadManualCSVFile(formData);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log("responseUploadCSVFile", response);
    //             notify(response.data.message);
    //         }
    //     } catch (error) {
    //         console.log("Error", error);
    //     }
    // };

    //Upload CSV File
    const handleUploadCSVFile = async () => {

        if (selectedMachineIds.length === 0) {
            notifyError('Please select a machine');
            return;
        }

        if (!selectedFile) {
            notifyError('Please select a file');
            return;
        }

        const formData = new FormData();
        formData.append('csv_file', selectedFile);

        // Append each machine ID to formData
        selectedMachineIds.forEach((id) => {
            formData.append('machine_ids', id);
        });

        try {
            setLoading(true)
            const response = await uploadManualCSVFile(formData);
            if (response.status === 200 || response.status === 201) {
                console.log("responseUploadCSVFile", response);
                setLoading(false)
                notify(response.data.message);
                setIsModalOpen(false);
                getFileHistory();
                setSelectedFile(null);
                setSelectedMachineIds([]);
                setCheckedMachines({});
                setSelectedUids([]);
            }
        } catch (error) {
            setLoading(false)
            console.log("Error", error);
            notifyError(error.response.data.message);
        }
    };

    const handleDeleteCSVFile = (id) => {
        setFileToDelete(id);
        setConfirmationModal(true);
    }

    const handleCancelDelete = () => {
        setConfirmationModal(false);
        setFileToDelete(null);
    };

    //DELETE CSV File History
    const handleConfirmDelete = async () => {
        try {
          const response =  await deleteCSVFileHistory(fileToDelete);
            notify(response.data.message);
            getFileHistory();
            // setFileHistory((prevFiles) => prevFiles.filter((file) => file.id !== fileToDelete));
        } catch (error) {
            console.error('Error deleting file', error);
            notifyError(error.response.data.message);
        } finally {
            setConfirmationModal(false);
            setFileToDelete(null);
        }
    };

    useEffect(() => {
        getMachineData();
        getFileHistory();
    }, [page, rowsPerPage]);

    return (
        <>
            <ToastContainer />
            {/* <Box className="upload-data-container"> */}
                {isModalOpen && (
                    <Modal
                        open={isModalOpen}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Container maxWidth="sm">
                            <Card className="upload-data-card">
                                <div className="upload-data-text-wrap">
                                    <div className="upload-data-text">
                                        Upload Data Files
                                    </div>
                                    <div>
                                        <img
                                            src={images.closeIcon}
                                            alt=""
                                            className="upload-modal-close-icon"
                                            onClick={handleClose}
                                        />
                                    </div>
                                </div>
                                <div className="upload-data-wrap">
                                    <div className="upload-data-dropdown-wrap">
                                        <FormControl className="upload-data-dropdown">
                                            <h6 className="select-machine-text">Select Machine</h6>
                                            <Button
                                                variant="contained"
                                                onClick={handleClick}
                                                className="product-drop"
                                            >
                                                Select Product
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleCloseMenu}
                                                className="select-pro-dropdown"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>

                                                {machineList.map((machine) => (
                                                    <MenuItem key={machine.id} value={machine.uid}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checkedMachines[machine.id] || false}
                                                                    onChange={(e) => handleCheck(e, machine.id)}
                                                                />
                                                            }
                                                            label={machine.uid}
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </FormControl>
                                        <div className="selected-uid-list pt-2">
                                            {selectedUids.length > 0 && (
                                                <>
                                                    <h6>Selected Machines</h6>
                                                    <ul>
                                                        {selectedUids.map((uid) => (
                                                            <li key={uid}>{uid}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="upload-data-input-wrap">
                                        <div>
                                            <input
                                                accept="*"
                                                style={{ display: 'none' }}
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={handleFileUpload}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    endIcon={<CloudUploadIcon />}
                                                    sx={{ backgroundColor: '#1c407b' }}
                                                >
                                                    Upload File
                                                </Button>
                                            </label>
                                        </div>
                                        <div>
                                            <button className="upload-data-submit-btn" onClick={handleUploadCSVFile}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="upload-data-bottom-text">
                                    <h6>
                                        Get Demo File
                                    </h6>
                                    <div>
                                        <button className="upload-data-download-btn" onClick={handleDownload}>
                                            Download
                                        </button>
                                    </div>
                                    <h4>
                                        Please select a machine to download the demo file
                                    </h4>
                                </div>
                            </Card>
                        </Container>
                    </Modal>
                )}
            {/* </Box> */}
            {/* Delete confirmation modal */}
            {
                configrationModal && (<div className="alarmData">
                    <Card className="confirmation-modal">
                        <CardContent>
                            <h4 className="card-content">
                                <h5
                                    style={{
                                        textAlign: "center",
                                        fontWeight: 500,
                                        fontSize: "22px",
                                    }}
                                >
                                    <b>
                                        Are You Sure<br></br> You Want To Delete?
                                    </b>
                                </h5>
                            </h4>
                        </CardContent>
                        <div style={{ textAlign: "center" }}>
                            <button className="alarmYes" onClick={handleConfirmDelete}>
                                Yes
                            </button>
                            <button className="alarmNo" onClick={handleCancelDelete}>No</button>
                        </div>
                    </Card>
                </div>)
            }

            <Container maxWidth="xxl" className="pt-2">
                <img
                    src={images.uploadLogo}
                    alt=""
                    className="uploadCSV"
                    onClick={handleOpen}
                />
                <div className="pt-4 tableResponsive accessManagement">
                    <Table aria-label="File History" className="deviceManagementTable">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sr No</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell>CSV File</TableCell>
                                <TableCell>Uploaded By</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fileHistory.length > 0 ? (
                                fileHistory.map((file, index) => (
                                    <TableRow key={file.id}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{file.created_at}</TableCell>
                                        <TableCell>
                                            <a href={file.csv_file} download>
                                                {file.csv_file.split("/").pop()}
                                            </a>
                                        </TableCell>
                                        <TableCell>{file.uploaded_by}</TableCell>
                                        <TableCell><img
                                            src={images.deleteImg}
                                            alt=""
                                            className="delete-icon"
                                            onClick={() => handleDeleteCSVFile(file.id)}
                                        /></TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No file history available.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={counHistoryData}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                    />
                </div>
            </Container>
            {isLoading && <Loader />}
        </>
    )
}

export default UploadData;