import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useParams } from "react-router-dom";
import { images } from "../../../config";

HC_more(Highcharts);
exporting(Highcharts);

export function InverterBarChart({ inverterData }) {
  const chartRef = useRef(null);
  const param = useParams();

  // Prepare data and labels using graph_data
  const graphData = inverterData?.graph_data || [];
  
  const labels = graphData.map((item) => item.MIN_KEY); // X-axis labels from MIN_KEY
  const currentData = graphData.map((item) => parseFloat(item.current_tag_value));
  const pastData = graphData.map((item) => parseFloat(item.past_tag_value));

  const dataSeries = [
    {
      name: "Current Tag Value",
      data: currentData,
      color: "#9A64B2",
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },
    {
      name: "Past Tag Value",
      data: pastData,
      color: "#D2D5DB",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ];

  const options = {
    chart: {
      type: "column",
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5);
          xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
          this.redraw();
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: dataSeries,
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const pointIndex = this.point.index;
        const pastDataPoint = pastData[pointIndex];
        const currentDataPoint = currentData[pointIndex];
        return `<b>${labels[pointIndex]}</b><br/>Current Value: ${currentDataPoint}<br/>Past Value: ${pastDataPoint}`;
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5;
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [inverterData]);

  return (
    <>
      {graphData.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
      ) : (
        <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No Data Found Img" />
      )}
    </>
  );
}
