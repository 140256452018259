import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

const HalfCircleGauge = ({ realTimeData, sliderRealTimeTagData, containerName }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!realTimeData || realTimeData.peak_value_today === undefined) {
      console.error("realTimeData or peak_value_today is undefined.");
      return;
    }

    // Extract Real-Time Data
    const RealTimeData = sliderRealTimeTagData.length
      ? sliderRealTimeTagData.find(
          (f) =>
            f.device__uid === realTimeData.device_uid &&
            f.device_tag === realTimeData.device_tag
        )?.["tagValue"] || 0.0
      : 0.0;

    const chartOptions = {
      chart: {
        type: "gauge",
        backgroundColor: null,
        plotBackgroundColor: "transparent",
        plotBorderWidth: 0,
        plotShadow: false,
        height: "50%",
      },
      title: { text: null },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ["50%", "75%"],
        size: "100%",
      },
      credits: { enabled: false },
      yAxis: {
        min: 0,
        max: 1000,
        tickPosition: "inside",
        tickLength: 15,
        minorTickInterval: null,
        labels: {
            enabled: false
        },
        plotBands: [
            { from: 0, to: 160, color: "#008f00", thickness: 30 },
            { from: 160, to: 320, color: "#76b500", thickness: 30 },
            { from: 320, to: 480, color: "#c1cf00", thickness: 30 },
            { from: 480, to: 640, color: "#ffdd00", thickness: 30 },
            { from: 640, to: 800, color: "#ff8c00", thickness: 30 },
            { from: 800, to: 1000, color: "#ff0000", thickness: 30 },
        ],
      },
      series: [
        {
          name: "Peak value",
          data: [Number(realTimeData.peak_value_today)],
          tooltip: { valueSuffix: " kW" },
          dial: {
            backgroundColor: "red",
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
            radius: "90%",
          },
          pivot: { backgroundColor: "red", radius: 7 },
        },
        {
          name: "Real-Time Data",
          data: [Number(RealTimeData)],
          tooltip: { valueSuffix: " kW" },
          dial: {
            backgroundColor: "black",
            baseWidth: 12,
            baseLength: "0%",
            rearLength: "0%",
            radius: "90%",
          },
          pivot: { backgroundColor: "black", radius: 7 },
        },
      ],
    };

    if (!chartRef.current) {
      chartRef.current = Highcharts.chart(containerName, chartOptions);
    } else {
      // Update Peak Value needle only if value has changed
      const peakValueData = Number(realTimeData.peak_value_today);
      if (chartRef.current.series[0].data[0].y !== peakValueData) {
        chartRef.current.series[0].data[0].update(peakValueData);
      }

      // Update Real-Time Data needle only if value has changed
      const realTimeDataValue = Number(RealTimeData);
      if (chartRef.current.series[1].data[0].y !== realTimeDataValue) {
        chartRef.current.series[1].data[0].update(realTimeDataValue, true, { duration: 500 });
      }
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [realTimeData, sliderRealTimeTagData, containerName]);

  return <div id={containerName} style={{ height: "100%" }}></div>;
};

export default HalfCircleGauge;
