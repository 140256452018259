import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
HC_more(Highcharts);
exporting(Highcharts);

const AnalyzeLineChart = ({ selectedColumnValues }) => {
    console.log("selectedColumnValues",selectedColumnValues)
  

  const lineColors = ["#FF0000", "#00FF00"];

  const labels = selectedColumnValues.map((_, index) => `Label ${index + 1}`);

  const seriesData = selectedColumnValues.map((value, index) => ({
    name: `Data Series ${index + 1}`,
    data: [parseFloat(value)], 
    color: lineColors[index % lineColors.length],
  }));

  const [keyValue, setKeyGraph] = useState(0);
  const [chartOption, setChartOption] = useState({
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: labels.length - 1,
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    legend: {
      itemStyle: {
        color: "black",
        fontSize: "16px",
      },
    },
    colors: lineColors,
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = `${this.x}<br />`;
        this.points.forEach((point) => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });
        return s;
      },
    },
  });

  useEffect(() => {
    setChartOption({
      ...chartOption,
      xAxis: {
        ...chartOption.xAxis,
        categories: labels,
      },
      series: seriesData, // Update series data
    });

    setKeyGraph(keyValue + 1);
  }, [selectedColumnValues]);

  return (
    <div style={{ height: "390px" }} key={keyValue}>
      <HighchartsReact highcharts={Highcharts} options={chartOption} />
    </div>
  );
};

export default AnalyzeLineChart;
