import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { images } from '../../../config';

HC_more(Highcharts);
exporting(Highcharts);

export function WeatherBarChart({ weatherGraphData }) {
    console.log("weatherGraphData", weatherGraphData);

    const chartRef = useRef(null);

    // Prepare data and labels using weatherGraphData.data
    const data = weatherGraphData[0]?.data || [];
    
    const dataSeries = [{
        name: 'AC Power (kW)',
        data: data.map(item => parseFloat(item.tag_value)),
        color: "#9A64B2"
    }];

    const labels = data.map(item => item.MIN_KEY);

    const options = {
        chart: {
            type: 'column',
            events: {
                load: function () {
                    const xAxis = this.xAxis[0];
                    const visibleCategories = labels.slice(-5);
                    xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
                    this.redraw();
                },
            },
        },
        title: {
            text: null
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: labels,
        },
        yAxis: {
            title: {
                text: null
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: 'gray',
                },
            },
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
            },
        },
        series: dataSeries,
        scrollbar: {
            enabled: true,
        },
        exporting: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
                const pointIndex = this.point.index;
                const dataPoint = data[pointIndex];

                let tooltipContent = `<b>${dataPoint?.MIN_KEY}</b><br/>${dataPoint?.tag_unit}: ${dataPoint?.tag_value} `;
                
                return tooltipContent;
            },
        },
    };

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (chart) {
            chart.options.xAxis.min = labels.length - 5;
            chart.options.xAxis.max = labels.length - 1;
            chart.redraw();
        }
    }, [weatherGraphData]);

    return (
        <>
            { data.length > 0 ? <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} /> : <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No Data Found Img" /> }
        </>
    );
}
