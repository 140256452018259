import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { images } from '../../../config';
import dayjs from 'dayjs';

HC_more(Highcharts);
exporting(Highcharts);

export function MultiAxisLineChart({ sliderRealTimeTagData }) {
    const { unitColorsReportRedux } = useSelector((state) => state.overViewState);

    const chartRef = useRef(null);
    const [dataSeries1, setDataSeries1] = useState([]);
    const [dataSeries2, setDataSeries2] = useState([]);
    const [xCategories, setXCategories] = useState([]);


    useEffect(() => {
        if (sliderRealTimeTagData.length > 1) {
            const newPoint1 = parseFloat(sliderRealTimeTagData[0]?.tagValue) || 0;
            const newPoint2 = parseFloat(sliderRealTimeTagData[1]?.tagValue) || 0;
            const newTimestamp = sliderRealTimeTagData[0]?.tagDateAndTime.split(", ")[1] || ""; // Extract time only

            // Add the new points and timestamp to state
            setDataSeries1(prevData => [...prevData, newPoint1]);
            setDataSeries2(prevData => [...prevData, newPoint2]);
            setXCategories(prevCategories => [...prevCategories, newTimestamp]);

            // Update the chart by resetting entire series data
            if (chartRef.current) {
                chartRef.current.chart.series[0].setData([...dataSeries1, newPoint1], true);
                chartRef.current.chart.series[1].setData([...dataSeries2, newPoint2], true);
                chartRef.current.chart.xAxis[0].setCategories([...xCategories, newTimestamp]);
            }
        }
    }, [sliderRealTimeTagData]);

const options = {
    chart: {
        type: 'spline',
       
    },
    legend:{
        enabled :false
    },
    title: {
        text: null,
    },
    scrollbar: {
        enabled: true,
    },
    exporting: {
        enabled: true,
    },
    xAxis: {
        categories: xCategories,
    },
    yAxis: [
        {
            title: { text: sliderRealTimeTagData[0]?.device_tag || '' },
        },
        {
            title: { text: sliderRealTimeTagData[1]?.device_tag || '' },
            opposite: true,
        },
    ],
    series: [
        {
            name: sliderRealTimeTagData[0]?.device_tag,
            data: dataSeries1,
            color: "#9A64B2",
            yAxis: 0,
        },
        {
            name: sliderRealTimeTagData[1]?.device_tag,
            data: dataSeries2,
            color: "#E579A0",
            yAxis: 1,
        },
    ],
    credits: {
        enabled: false,
    },
    tooltip: {
        shared: true,
        formatter: function () {
            let s = `${this.x}<br/>`;
            this.points.forEach(point => {
                s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
            });
            return s;
        },
    },
};


    return (
        <>
            {dataSeries1.length || dataSeries2.length ? (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                />
            ) : (
                <img src={images.noDataFoundImg} className="notFoundDataImg" alt='No DataFound Img' style={{ paddingTop: "100px" }} />
            )}
        </>
    );
}
