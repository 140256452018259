import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../config";

HC_more(Highcharts);
exporting(Highcharts);

export function GenerationBarChart({ generationData,showGenerationAverage }) {
    console.log("generationData",generationData)
  const chartRef = useRef(null);
  const param = useParams();

  // Prepare data and labels using only past_data
  const pastData = generationData?.past_data || [];
  const currentData = generationData?.current_data || [];
  const averageData = generationData?.average_data || [];


  const averageValues = generationData?.average_data?.map(item => item.average_value) || [];



  const labels = [];

  if (generationData?.past_data || generationData?.current_data) {
    const currentData = generationData.current_data || []; // Make sure it's an array
    const pastData = generationData.past_data || []; // Make sure it's an array
    const popDataLength = currentData.length;
    const pop1DataLength = pastData.length;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    console.log("maxLength", maxLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = currentData[i] || {}; // Fallback to empty object if undefined
      const pop1Data = pastData[i] || {}; // Fallback to empty object if undefined
      const minKey = popData.MIN_KEY || "";
      const pop1MinKey = pop1Data.PAST_MIN_KEY || "";
      console.log("minKey", minKey, pop1MinKey);
      const labeledMinKey = `<span style="color: #9A64B2;">${minKey}</span>`;
      const labeledPop1MinKey = `<span style="color: #D2D5DB;">${pop1MinKey}</span>`;
      labels.push(`${labeledMinKey} ${labeledPop1MinKey}`);
    }
  }

  console.log("currentData", currentData, pastData);

  //   const dataSeries = [{
  //     name: 'Past Tag Value',
  //     data: pastData.map(item => parseFloat(item.past_tag_value)),
  //     color: "#9A64B2"
  //   }];

  const dataSeries = [
    {
      name: "",
      data: currentData.map((item) => parseFloat(item.tag_value)),
      color: "#9A64B2",
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },

    {
      name: "",
      data: pastData.map((item) => parseFloat(item.past_tag_value)),
      color: "#D2D5DB",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ];
  //   const labels = pastData.map(item => item.PAST_MIN_KEY.split(" ")[1].substring(0, 5));

  const options = {
    chart: {
      type: "column",
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5);
          xAxis.setExtremes(
            labels.length - visibleCategories.length,
            labels.length - 1,
            false
          );
          this.redraw();
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
        ...dataSeries,
        ...(showGenerationAverage ? [{
          name: 'Average Value',
          data: averageValues,
          type: 'line',
          dashStyle: 'Dash',
          color: 'green',
          marker: {
            enabled: false
          }
        }] : [])
      ],
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const pointIndex = this.point.index;
        const pastDataPoint = pastData[pointIndex];
        const currentDataPoint = currentData[pointIndex];
        const averageData1 = averageData[pointIndex]
        let tooltipContent = `<b>${pastDataPoint?.PAST_MIN_KEY}</b><br/>Current Value: ${currentDataPoint?.tag_value}<br/>Past Value: ${pastDataPoint?.past_tag_value}`;
        if (showGenerationAverage && averageData1.average_value) {
            tooltipContent += `<br/>Average Value: ${averageData1.average_value}`;
          }
        return tooltipContent;
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5;
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [generationData]);

  return (
    <>
      {pastData.length > 0 ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      ) : (
        <img
          src={images.noDataFoundImg}
          className="notFoundDataImg"
          alt="No Data Found Img"
        />
      )}
    </>
  );
}
