import React, { useEffect, useMemo, useState } from "react";
import { images } from "../../../config";
import { Box, Grid, Typography } from "@mui/material";
import PlantLocation from "../../../components/BPCL/PlantLocation/PlantLocation";
import "./Analyze.css";

import Mqqtdata from "../../ProcessDashboard/Mqqtdata";
import {
  getHikemmREPlantDashboardCo2Count,
  getHikemmREPlantDashboardCostSavingCount,
  getHikemmREPlantDashboardGenerationCount,
  getHikemmREPlantDashboardTags,
  getHikemmREPlantDashboardUtilizationCount,
} from "../services";
import { BPCLPlant } from "../../CompanyDashboard/services";
import { useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../../components/BPCL/Datepicker/Datepicker";
import { PieChartBPCL } from "../../../components/Graph/BPCL/Analyze-Generation-pie-chart";
import { BarChartBPCL } from "../../../components/Graph/BPCL/Analyze-Generation-Bar-chart";
import { PieChartUtiBPCL } from "../../../components/Graph/BPCL/Analyze-Utilization-Pie-Chart";
import { BarChartUtiBPCL } from "../../../components/Graph/BPCL/Analyze-Utilization-Bar-Chart";
import { PieChartCostBPCL } from "../../../components/Graph/BPCL/Analyze-Cost-Pie-Chart";
import { BarChartCostBPCL } from "../../../components/Graph/BPCL/Analyze-Cost-Bar-Chart";
import { PieChartCo2BPCL } from "../../../components/Graph/BPCL/Analyze-Co2-Pie-Chart";
import { BarChartCo2BPCL } from "../../../components/Graph/BPCL/Analyze-Co2-Bar-Chart";
import { useSelector } from "react-redux";

const Analyze = () => {
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [active, setActive] = useState("");
  const [selectedChart, setSelectedChart] = useState("pie");
  const [utiselectedChart, setUtiSelectedChart] = useState("pie");
  const [costSelectedChart, setCostSelectedChart] = useState("pie");
  const [bpclselectedChart, setBpclSelectedChart] = useState("pie");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeSwitches, setActiveSwitches] = useState([]);
  const [generationCountData, setGenerationCountData] = useState([]);
  const [utilizationCountData, setUtilizationCountData] = useState([]);
  const [costSavingCountData, setCostSavingCountData] = useState([]);
  const [co2CountData, setCo2CountData] = useState([]);
  const [allGenerationCountData, setAllGenerationCountData] = useState([]);
  const [allUtiCountData, setAllUtiCountData] = useState([]);
  const [allCostCountData, setAllCostCountData] = useState([]);
  const [allCo2CountData, setAllCo2CountData] = useState([]);

  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  useEffect(() => {
    if (activeSwitches && startDate && endDate) {
      handleGenerationAPI();
      handleUtilizationAPI();
      handleCo2API();
      handleCostSavingAPI();
    }
  }, [startDate, endDate, activeSwitches]);

  const handleGenerationAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      generation_tag_unit: "kWH_YIELD",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardGenerationCount(params);
      if (resp.data.success == true) {
        setGenerationCountData(resp.data.data);
        setAllGenerationCountData(resp.data);
      }
    } catch (error) {
      setGenerationCountData([]);
      console.log("handlesubmit", error);
    }
  };
  const handleUtilizationAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      utilization_tag_unit: "%",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardUtilizationCount(params);
      if (resp.data.success == true) {
        setUtilizationCountData(resp.data.data);
        setAllUtiCountData(resp.data);
      }
    } catch (error) {
      setUtilizationCountData([]);
      console.log("handlesubmit", error);
    }
  };
  const handleCostSavingAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      cost_saving_tag_unit: "Amp_R",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCostSavingCount(params);
      if (resp.data.success == true) {
        setCostSavingCountData(resp.data.data);
        setAllCostCountData(resp.data);
      }
    } catch (error) {
      setCostSavingCountData([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCo2API = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      co2_green_tag_unit: "kWH_YIELD",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCo2Count(params);
      if (resp.data.success == true) {
        setCo2CountData(resp.data.data);
        setAllCo2CountData(resp.data);
      }
    } catch (error) {
      setCo2CountData([]);
      console.log("handlesubmit", error);
    }
  };

  const params = useParams();

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.companyId,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };
  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds]);

  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "kWH_TOTAL",
      utilization_tag_unit: "%",
      cost_saving_tag_unit: "Amp_R",
      co2_green_tag_unit: "kWH_YIELD",
    };
    try {
      const resp = await getHikemmREPlantDashboardTags(params);
      if (resp.status == 200 || resp.status == 201) {
        setRealTimePlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  const handleClick = (type) => {
    setActive((prevActive) => (prevActive === type ? null : type));
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="Bpcl-page-wrapper">
        {realTimeData.length ? (
          <Mqqtdata
            setMqttTagData={setMqttTagData}
            topics={topics}
            overViewTagData={realTimeData}
          />
        ) : (
          ""
        )}
        <Grid container>
          <Grid item xs={12} md={3}>
            <PlantLocation
              mqttTagData={mqttTagData}
              realTimePlantDta={realTimePlantDta}
              plantData={plantData}
              active={active}
              handleClick={handleClick}
              activeSwitches={activeSwitches}
              setActiveSwitches={setActiveSwitches}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Box className="map-box">
              <div className="d-flex justify-content-end">
                <Datepicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
                <img
                  alt=""
                  src={images.nextIco}
                  style={{
                    height: "30px",
                    width: "30px",
                    marginTop: "18px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      `/bpcl-analyze-next-screen/company/${params.companyId}`
                    )
                  }
                />
              </div>
              <div className="generation-data-div">
                <div className="d-flex gap-3">
                  <div>
                    <img src={images.powerImg} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6>GENERATION</h6>
                    <h5>
                      <b className="gen-data-unit">
                        {selectBPCLUnits == "k"
                          ? allGenerationCountData?.total_generation_value_in_kilo
                          : selectBPCLUnits == "m"
                          ? allGenerationCountData?.total_generation_value
                          : allGenerationCountData?.total_generation_value_in_giga}
                      </b>
                      <span>MWh</span>
                    </h5>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-3">
                  <div>
                    <img src={images.utilizationImg} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6>UTILIZATION</h6>
                    <h5>
                      <b className="gen-data-unit">
                        {allUtiCountData.total_utilization_value}
                      </b>
                      <span>MWh</span>
                    </h5>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-3">
                  <div>
                    <img src={images.costSavings} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6>COST SAVINGS</h6>
                    <h5>
                      <b className="gen-data-unit">
                        {allCostCountData?.total_cost_saving_value}
                      </b>
                      <span>MWh</span>
                    </h5>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-3">
                  <div>
                    <img src={images.ecology} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6>BPCL GREEN</h6>
                    <h5>
                      <b className="gen-data-unit">
                        {selectBPCLUnits == "k"
                          ? allGenerationCountData?.total_co2_green_value_in_kilo
                          : selectBPCLUnits == "m"
                          ? allGenerationCountData?.total_co2_green_value
                          : allGenerationCountData?.total_co2_green_value_in_giga}
                      </b>
                      <span>MWh</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="generation-pie-bar-chart-div pt-5">
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            selectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedChart("pie")}
                        />
                        <img
                          src={
                            selectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {selectedChart === "pie" ? (
                          <PieChartBPCL
                            countData={generationCountData}
                            activeSwitches={activeSwitches}
                          />
                        ) : (
                          <BarChartBPCL
                            countData={generationCountData}
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>
                      <div className="pt-4 chart-title">
                        <p>Generation</p>
                      </div>
                      <hr />
                      <div className="pie-chart-box-data">
                        {generationCountData.map((e) => {
                          return (
                            <div className="d-flex justify-content-between pt-1">
                              <p className="mb-0 truncated">{e.plant_name}</p>
                              <p className="mb-0">{e.generation_value}MWh</p>
                            </div>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            utiselectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUtiSelectedChart("pie")}
                        />
                        <img
                          src={
                            utiselectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUtiSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {utiselectedChart === "pie" ? (
                          <PieChartUtiBPCL
                            countData={utilizationCountData}
                            activeSwitches={activeSwitches}
                          />
                        ) : (
                          <BarChartUtiBPCL
                            countData={utilizationCountData}
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>
                      <div className="pt-4 chart-title">
                        <p>Utilization</p>
                      </div>
                      <hr />
                      <div className="pie-chart-box-data">
                        {utilizationCountData.map((e) => {
                          return (
                            <div className="d-flex justify-content-between pt-1">
                              <p className="mb-0 truncated">{e.plant_name}</p>
                              <p className="mb-0">{e.utilization_value}MWh</p>
                            </div>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            costSelectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setCostSelectedChart("pie")}
                        />
                        <img
                          src={
                            costSelectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setCostSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {costSelectedChart === "pie" ? (
                          <PieChartCostBPCL
                            countData={costSavingCountData}
                            activeSwitches={activeSwitches}
                          />
                        ) : (
                          <BarChartCostBPCL
                            countData={costSavingCountData}
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>
                      <div className="pt-4 chart-title">
                        <p>Cost Savings</p>
                      </div>
                      <hr />
                      <div className="pie-chart-box-data">
                        {costSavingCountData.map((e) => {
                          return (
                            <div className="d-flex justify-content-between pt-1">
                              <p className="mb-0 truncated">{e.plant_name}</p>
                              <p className="mb-0">{e.cost_saving_value}MWh</p>
                            </div>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            bpclselectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setBpclSelectedChart("pie")}
                        />
                        <img
                          src={
                            bpclselectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setBpclSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {bpclselectedChart === "pie" ? (
                          <PieChartCo2BPCL
                            countData={co2CountData}
                            activeSwitches={activeSwitches}
                          />
                        ) : (
                          <BarChartCo2BPCL
                            countData={co2CountData}
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>
                      <div className="pt-4 chart-title">
                        <p>BPCL Green</p>
                      </div>
                      <hr />
                      <div className="pie-chart-box-data">
                        {co2CountData.map((e) => {
                          return (
                            <div className="d-flex justify-content-between pt-1">
                              <p className="mb-0 truncated">{e.plant_name}</p>
                              <p className="mb-0">{e.co2_green_value}MWh</p>
                            </div>
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Analyze;
