import {
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import "../../../components/BPCL/BPCLWeathers/BPCLWeathers.css";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { WeatherBarChart } from "../../Graph/BPCL/Weather-bar-chart";
import HalfCircleGauge from "../../Graph/BPCL/Speedometer";
import { MultiAxisLineChart } from "../../Graph/BPCL/MultiAxis-line-chart";

const BPCLWeathers = (props) => {
  console.log(
    "sliderRealTimeTagDatasliderRealTimeTagData",
    props.sliderRealTimeTagData
  );

  const [dateRange, setDateRange] = React.useState("Today");

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };
  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
      ],
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "MWh",
        data: [3000, 4000, 5000, 6000, 7000, 8000, 9000],
        color: "#9C27B0",
      },
    ],
  };

  const barChartOptions = {
    chart: { type: "column" },
    title: { text: null },

    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
      ],
    },
    yAxis: { title: { text: "MWh" }, max: 10000 },
    series: [
      {
        name: "Data",
        data: [6000, 9000, 7500, 9500, 7000, 6000, 8500, 8000],
        color: "#a16eff",
      },
    ],
  };

  const lineChartOptions = {
    chart: { type: "line" },
    title: { text: null },
    credits: {
      enabled: false,
    },

    xAxis: { categories: ["1 Min", "2 Min", "3 Min", "4 Min", "5 Min"] },
    yAxis: { title: { text: "W/m²" }, max: 10000 },
    series: [
      {
        name: "Ambient Temp",
        data: [2000, 3000, 2500, 9000, 4000, 5000, 1200, 1560],
        color: "#a16eff",
      },
      {
        name: "Module Temp",
        data: [1000, 2000, 1500, 8000, 3500, 2500, 9000, 4000],
        color: "#e056fd",
      },
    ],
  };
  return (
    <div>
      <Box className="pt-2">
        <Card className="availability-box">
          <Typography
            variant="h6"
            className="typography_Growth"
            style={{ fontWeight: "600" }}
          >
            Weather
          </Typography>

          <Grid container spacing={2} className="custom-grid mt-3">
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">GTI</h5>
                </div>
                <div>
                  {" "}
                  {props.realTimeData[0] && (
                    <HalfCircleGauge
                      realTimeData={props.realTimeData[0]}
                      sliderRealTimeTagData={props.sliderRealTimeTagData}
                      containerName={"guage_1"}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">Wind Speed</h5>
                </div>
                <div>
                  {props.realTimeData[1] && (
                    <HalfCircleGauge
                      realTimeData={props.realTimeData[1]}
                      sliderRealTimeTagData={props.sliderRealTimeTagData}
                      containerName={"guage_2"}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">Ambient Temp.</h5>
                </div>
                <div>
                  {props.realTimeData[2] && (
                    <HalfCircleGauge
                      realTimeData={props.realTimeData[2]}
                      sliderRealTimeTagData={props.sliderRealTimeTagData}
                      containerName={"guage_3"}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="custom-grid mt-3">
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  {" "}
                  <h5 className="weather_BPCL">Ambient Temp.</h5>
                </div>
                <div>
                  {" "}
                  <HalfCircleGauge
                    realTimeData={props.realTimeData[3]}
                    sliderRealTimeTagData={props.sliderRealTimeTagData}
                    containerName={"guage_4"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">GHI</h5>
                </div>
                <div>
                  <HalfCircleGauge
                    realTimeData={props.realTimeData[4]}
                    sliderRealTimeTagData={props.sliderRealTimeTagData}
                    containerName={"guage_5"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="card_weather">
                <div>
                  <h5 className="weather_BPCL">Module Temp.</h5>
                </div>
                <div>
                  <HalfCircleGauge
                    realTimeData={props.realTimeData[5]}
                    sliderRealTimeTagData={props.sliderRealTimeTagData}
                    containerName={"guage_6"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Box className="pt-3">
            <div className="BPCL_weather_chart mb-3">
              <Grid item xs={12} md={3} lg={3}>
                <Select
                  variant="outlined"
                  fullWidth
                  value={props.weatherValue}
                  onChange={props.handleDateWeatherValue}
                  className="inpute-week"
                >
                  <MenuItem value={1}>GTI</MenuItem>
                  <MenuItem value={2}>GHI</MenuItem>
                  <MenuItem value={3}>Wind Speed</MenuItem>
                  <MenuItem value={4}>Amb.Temp.</MenuItem>
                  <MenuItem value={5}>Module Temp.</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Select
                  value={props.weatherTime}
                  onChange={props.handleDateWeatherTime}
                  className="inpute-week"
                >
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Week</MenuItem>
                  <MenuItem value={3}>Month</MenuItem>
                  <MenuItem value={4}>Quarter</MenuItem>
                  <MenuItem value={5}>Year</MenuItem>
                </Select>
              </Grid>
               <Grid item xs={12} md={9} lg={9}>
               <button className="live-genration">Live</button>
              </Grid>
            </div>

            <Grid container spacing={2}>
              {/* Second row: Charts */}
              <Grid item xs={12} md={6} lg={7}  style={{borderRight:"1px solid #f5efef", }}>
                <Box p={2}>
                  <WeatherBarChart weatherGraphData={props.weatherGraphData} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Box p={2}>
                  <MultiAxisLineChart
                    sliderRealTimeTagData={props.sliderRealTimeTagData}
                    realTimeData={props.realTimeData}
                  />
                </Box>
              </Grid>

              {/* Third row: Peak Values */}
            </Grid>
            <Grid xs={12} sm={12} md={12}>
            <Grid container spacing={3}>
              <Grid item md={2.4} sm={12}>
                <h5 style={{ fontWeight: "bold" }}>GTI</h5>
                <div className="pt-1 d-flex gap-2">
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility  ">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.gti?.peak_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.gti?.tag_unit}
                      </span>
                    </div>
                  </button>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.gti?.all_time_peak_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.gti?.tag_unit}
                      </span>
                    </div>
                  </button>
                </div>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <h5 style={{ fontWeight: "bold" }}>GHI</h5>
                <div className="pt-1 d-flex gap-2">
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.ghi?.peak_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.ghi?.tag_unit}
                      </span>
                    </div>
                  </button>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.ghi?.all_time_peak_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.ghi?.tag_unit}
                      </span>
                    </div>
                  </button>
                </div>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <h5 style={{ fontWeight: "bold" }}>Wind Speed</h5>
                <div className="pt-1 d-flex gap-2">
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.wind_speed?.peak_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.wind_speed?.tag_unit}
                      </span>
                    </div>
                  </button>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.wind_speed
                            ?.all_time_peak_value
                        }
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {props.weatherGraphData[0]?.wind_speed?.tag_unit}
                      </span>
                    </div>
                  </button>
                </div>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <h5 style={{ fontWeight: "bold" }}>Ambient Temperature</h5>
                <div className="pt-1 d-flex gap-2">
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.ambient_temperature
                            ?.peak_value
                        }
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.ambient_temperature
                            ?.tag_unit
                        }
                      </span>
                    </div>
                  </button>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.ambient_temperature
                            ?.all_time_peak_value
                        }
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.ambient_temperature
                            ?.tag_unit
                        }
                      </span>
                    </div>
                  </button>
                </div>
              </Grid>
              <Grid item md={2.4} sm={12}>
                <h5 style={{ fontWeight: "bold" }}>Module Temperature</h5>
                <div className="pt-1 d-flex gap-2">
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                      {
                                props.weatherGraphData[0]?.module_temperature
                                  ?.peak_value
                              }

                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.module_temperature
                            ?.tag_unit
                        }
                      </span>
                    </div>
                  </button>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility">
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.module_temperature
                            ?.all_time_peak_value
                        }
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                        {
                          props.weatherGraphData[0]?.module_temperature
                            ?.tag_unit
                        }
                      </span>
                    </div>
                  </button>
                </div>
              </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </div>
  );
};

export default BPCLWeathers;
