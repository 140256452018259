import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../../container/ProcessDashboard/ProcessDashboard.css";
import { images } from "../../../config";

export function AvailabilityDonutChart({ sliderRealTimeTagData,text }) {
    const chartRef = useRef(null);

    console.log("Slider Real-Time Tag Data:", sliderRealTimeTagData); // Check data

    // Extracting the tag value and parsing it
    const tagValue = sliderRealTimeTagData?.tagValue ? parseFloat(sliderRealTimeTagData?.tagValue) : 0;
    
    // Calculating the remaining value for the pie chart
    const remainingValue = 100 - tagValue; // Remaining value to make the total 100

    const options = {
        chart: {
            type: "pie",
            height: 250,
            width: 250,
            backgroundColor: "transparent",
        },
        title: {
            text: tagValue.toFixed(2),
            align: 'center',
            verticalAlign: 'middle',
            y: 10, // Adjusted upward
            style: {
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#000',
            },
        },
        subtitle: {
            text: text,
            align: 'center',
            verticalAlign: 'middle',
            y: 30, // Positioned below the title
            style: {
                fontSize: '16px',
                color: '#666',
            },
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            formatter: function () {
                const total = this.series.data.reduce((acc, point) => acc + point.y, 0);
                const percentage = ((this.y / total) * 100).toFixed(2) + "%";
                const deviceName = this.point.name;
                const deviceColor = this.point.color;
                return `<div style="font-size: 12px">
                          <b>${deviceName}</b><br/>
                          <span style="color:${deviceColor}">\u25A0</span> ${this.y} (${percentage})
                        </div>`;
            },
        },
        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Values",
                data: [
                    {
                        name: `Current Tag Value`,
                        y: tagValue,
                        color: "#5A8A8A", // Pink color for the tag value
                    },
                    {
                        name: "Remaining",
                        y: remainingValue,
                        color: "#D2D5DB", // Gray color for the remaining part
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chart.update(options);
        }
    }, [sliderRealTimeTagData]);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "340px" }} className="doughnut-chart1">
            {tagValue !== 0 ? ( // Check to render chart only if tagValue is not zero
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            ) : (
                <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="No data found" />
            )}
        </div>
    );
}
