import { request } from "../../api/api";

export const plantDashboardEfficiencyCount = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardEfficiancyCount/",
    params
  );
};

export const hikemmREPlantDashboardCount = (params) => {
  return request.post("/dashboard/getHikemmREPlantDashboardCount/", params);
};

export const getPlantProcessDashboardPlantPeak = (params) => {
  return request.post("/dashboard/getPlantProcessDashboardPlantPeak/", params);
};

export const getHikemmREPlantDashboardGeneration = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardGeneration/",
    params
  );
};
export const getHikemmREPlantDashboardGenerationBox = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardGenerationBox/",
    params
  );
};

export const getPlantProcessDashboardWeatherGraphData = (params) => {
  return request.post(
    "/dashboard/getPlantProcessDashboardWeatherGraphData/",
    params
  );
};

export const getHikemmREPlantDashboardTags = (params) => {
  return request.post("/dashboard/getHikemmREPlantDashboardTags/", params);
};
export const exportGenerationReportExcel = (params) => {
  return request.post("/dashboard/exportGenerationReportExcel", params);
};
export const getHikemmREPlantDashboardInverter = (params) => {
  return request.post("/dashboard/getHikemmREPlantDashboardInverter/", params);
};
export const getPlantProcessDashboardCostSavingData = (params) => {
  return request.post(
    "/dashboard/getPlantProcessDashboardCostSavingData/",
    params
  );
};
export const getPlantProcessDashboardCo2Data = (params) => {
  return request.post("/dashboard/getPlantProcessDashboardCo2Data/", params);
};
export const getPlantProcessDashboardWeatherPeakData = (params) => {
  return request.post(
    "/dashboard/getPlantProcessDashboardWeatherPeakData/",
    params
  );
};

export const getHikemmREPlantDashboardGenerationCount = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardGenerationCount/",
    params
  );
};

export const getHikemmREPlantDashboardUtilizationCount = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardUtilizationCount/",
    params
  );
};
export const getHikemmREPlantDashboardCostSavingCount = (params) => {
  return request.post(
    "/dashboard/getHikemmREPlantDashboardCostSavingCount/",
    params
  );
};
export const getHikemmREPlantDashboardCo2Count = (params) => {
  return request.post("/dashboard/getHikemmREPlantDashboardCo2Count/", params);
};



export const getHikemmREPlantAnalyzeDashboardTags = (params) => {
  return request.post("/dashboard/getHikemmREPlantAnalyzeDashboardTags/", params);
};
