import React, { useEffect, useMemo, useState } from "react";
import PlantLocation from "../../../components/BPCL/PlantLocation/PlantLocation";
import { Typography } from "antd";
import {
  Box,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { images } from "../../../config";
import {
  getHikemmREPlantAnalyzeDashboardTags,
  getHikemmREPlantDashboardTags,
} from "../services";
import Mqqtdata from "../../ProcessDashboard/Mqqtdata";
import { BPCLPlant } from "../../CompanyDashboard/services";
import { useParams } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PlantLocationAnalyze from "./Plant-Location-Analyze";
import AnalyzeLineChart from "../../../components/Graph/BPCL/Analyze-Line-Chart";

const AnalyzeNext = () => {
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);

  const [mqttTagDataAnalyze, setMqttTagDataAnalyze] = useState([]);
  const [topicsAnalyze, setTopicsAnalyze] = useState([]);

  const [plantData, setPlantData] = useState([]);
  const [active, setActive] = useState("");
  const [activeSwitches, setActiveSwitches] = useState([]);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [graphData, setGraphData] = useState(null);

  const params = useParams();

  const mergedArray = mqttTagDataAnalyze
    ? [].concat(...Object.values(mqttTagDataAnalyze))
    : [];

  const handleClick = (type) => {
    setActive((prevActive) => (prevActive === type ? null : type));
  };

  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (tagUnitData.length) {
      const topicArray = tagUnitData.flatMap((plant) =>
        Object.values(plant.tags).map(
          (tag) => `Hikar/DeviceUID/${tag.device_uid}`
        )
      );
      setTopicsAnalyze(topicArray);
    }
  }, [tagUnitData]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.companyId,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };

  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds]);

  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "kWH_TOTAL",
      utilization_tag_unit: "%",
      cost_saving_tag_unit: "Amp_R",
      co2_green_tag_unit: "kWH_YIELD",
    };
    try {
      const resp = await getHikemmREPlantDashboardTags(params);
      if (resp.status == 200 || resp.status == 201) {
        setRealTimePlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handlePlantAnalyzeDashboardTags();
  }, [activeSwitches]);

  const handlePlantAnalyzeDashboardTags = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      position_1_tag_unit: "kW_AC",
      position_2_tag_unit: "Amp_B",
      position_3_tag_unit: "Volt_BR",
      position_4_tag_unit: "kW_DC",
      position_5_tag_unit: "Hz",
      position_6_tag_unit: "Cost_INR",
    };
    try {
      const resp = await getHikemmREPlantAnalyzeDashboardTags(params);
      if (resp.data.success == true) {
        setTagUnitData(resp.data.payload);
      }
    } catch (error) {
      setTagUnitData([]);
      console.log("handlesubmit", error);
    }
  };

  return (
    <>
      <div className="Bpcl-page-wrapper">
        <>
          {realTimeData.length ? (
            <Mqqtdata
              setMqttTagData={setMqttTagData}
              topics={topics}
              overViewTagData={realTimeData}
            />
          ) : (
            ""
          )}
          {tagUnitData.length ? (
            <Mqqtdata
              setMqttTagData={setMqttTagDataAnalyze}
              topics={topicsAnalyze}
              overViewTagData={tagUnitData}
            />
          ) : (
            ""
          )}
          <Grid container>
            <Grid item xs={12} md={3}>
              <PlantLocationAnalyze
                mqttTagData={mqttTagData}
                realTimePlantDta={realTimePlantDta}
                plantData={plantData}
                active={active}
                handleClick={handleClick}
                activeSwitches={activeSwitches}
                setActiveSwitches={setActiveSwitches}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Box className="map-box">
                <div className="bpcl-line-chart">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img src={images.preIco} alt="" />
                    </div>
                    <div className="d-flex gap-2">
                      <h6>
                        Average <Switch />
                      </h6>
                      <h6 className="mt-2">Interval</h6>

                      <select
                        className="form-input-class"
                        style={{
                          padding: "1px 3px",
                          borderRadius: "4px",
                          height: "35px",
                          // marginRight: "20px",
                        }}
                        id="selectedClass"
                        required
                      >
                        <option value="1" selected disabled hidden>
                          1 min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </div>
                  </div>
                  <div style={{ height: "400px" }}></div>
                </div>
                <div
                  className="report-overview-table"
                  style={{ height: "324px" }}
                >
                  <Table className="source-table bpcl-report-tab">
                    <TableHead>
                      <TableRow>
                        <TableCell>Plants</TableCell>
                        {tagUnitData.map((plant) =>
                          Object.keys(plant.tags).map(
                            (positionKey, columnIndex) => {
                              const tagData = plant.tags[positionKey];

                              return (
                                <TableCell key={tagData.tag_id}>
                                  <div>
                                    <div>
                                      <img src={images.lineIco} alt="" />
                                    </div>

                                    <div className="d-flex gap-2">
                                      <p className="mb-0">{tagData.tag_unit}</p>
                                      <ArrowUpwardIcon
                                        fontSize="small"
                                        color="error"
                                      />
                                      <p>
                                        <b>12%</b>
                                      </p>
                                    </div>
                                  </div>
                                </TableCell>
                              );
                            }
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeSwitches.map((e, rowIndex) => {
                        const matchedTags = tagUnitData.flatMap((plant) =>
                          Object.values(plant.tags).map((tag) => {
                            const match = mergedArray.find(
                              (item) =>
                                item.device__uid === tag.device_uid &&
                                item.device_tag === tag.device_tag
                            );
                            return match ? match.tagValue : "-";
                          })
                        );

                        console.log("matchedTags", matchedTags);
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell
                              style={{ width: "200px", whiteSpace: "nowrap" }}
                            >
                              {e.name}
                            </TableCell>

                            {matchedTags.map((value, cellIndex) => (
                              <TableCell key={cellIndex}>{value}</TableCell> // Display value or "-"
                            ))}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Grid>
          </Grid>
        </>
      </div>
    </>
  );
};

export default AnalyzeNext;
