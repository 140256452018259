import {request} from "../../api/api"

export const getCompany = (params) =>{
    return request.post("/dashboard/getCompanyList/",params)
}

export const getPlantByCompanyApi = (params) =>{
    return request.post("/dashboard/getPlantList/",params)
}

export const getPlant = (params) =>{
    return request.post("/dashboard/getPlant/",params)
}
export const BPCLPlant = (params,active) =>{
    return request.post(`/dashboard/getPlant/${active == "solar" ? "?is_solar=true" : active == "wind" ? "?is_wind=true" : ""}`,params)
}

export const getProductDevice = () =>{
    return request.get(`/superadmin_dashboard/getProductDevice/`)
}
