import React, { useEffect, useState } from "react";
import BPCLAvailability from "../../../components/BPCL/BPCLavailability/BPCLavailability";
import BPCLGeneration from "../../../components/BPCL/BPCLGeneration/BPCLGeneration";
import BPCLWeathers from "../../../components/BPCL/BPCLWeathers/BPCLWeathers";
import BPCLInverters from "../../../components/BPCL/BPCLInverters/BPCLInverters";
import { Grid, Box, Typography } from "@mui/material";
import "../../../container/BPCL/BPCL-ProcessDashboard/BPCL-ProcessDashboard.css";
import {
  getHikemmREPlantDashboardGeneration,
  getHikemmREPlantDashboardGenerationBox,
  getHikemmREPlantDashboardInverter,
  getPlantProcessDashboardCo2Data,
  getPlantProcessDashboardCostSavingData,
  getPlantProcessDashboardPlantPeak,
  getPlantProcessDashboardWeatherGraphData,
  getPlantProcessDashboardWeatherPeakData,
} from "../services";
import { useNavigate, useParams } from "react-router-dom";
import BPCLCost from "../../../components/BPCL/BPCL-Cost/BPCLCost";
import OverViewMachineMqttData from "../../SmartTag/MachineDashboard/mqttDataMachineDashboard/OverViewMachineMqttData";
import { useSelector } from "react-redux";
import { SoftTagFormula } from "../../SmartTag/softTag/SoftTagFormula";

const BPCLProcessDashboard = () => {
  const [plantPeakData, setPlantPeakData] = useState([]);

  const param = useParams();
  const [dateRange, setDateRange] = React.useState(1);
  const [generationTime, setGenerationTime] = React.useState(1);
  const [generationValue, setGenerationValue] = React.useState(1);
  const [generationData, setGenerationData] = useState([]);
  const [generationBoxData, setGenartionBoxData] = useState({});
  const [weatherTime, setWeatherTime] = useState(1);
  const [weatherValue, setWeatherValue] = useState(1);
  const [weatherGraphData, setWeatherGraphData] = useState([]);
  const [inverterData, setInverterData] = useState([]);
  const [inverterTime, setInverterTime] = useState(1);
  const [costData, setCostData] = useState([]);
  const [costTime, setCostTime] = useState(1);
  const [co2Data, setCo2Data] = useState([]);
  const [co2Time, setCo2Time] = useState(1);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [tagListDataGroupAndList, setTagListDataGroupAndList] = useState([]);
  const [previousValues, setPreviousValues] = useState({});

  const { selectTimeZoneProfile, is_renewable_energy } = useSelector(
    (state) => state.userState
  );

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const mqttDataReduce = sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});
  console.log("mqttDataReduce", mqttDataReduce);
  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        // Handle soft tags
        if (row.is_soft_tag && row.soft_tag_id__formula) {
          // Compute current value using the formula
          const evaluatedCurrentValue = SoftTagFormula(
            row.soft_tag_id__formula,
            mqttDataReduce
          )?.value;
          console.log("Evaluated current value:", evaluatedCurrentValue);

          // Set the evaluated value as the new previous value
          setPreviousValues((prev) => ({
            ...prev,
            [row.soft_tag_id__formula]: evaluatedCurrentValue,
          }));

          // Use the previous value from state
          const evaluatedPreviousValue =
            previousValues[row.soft_tag_id__formula] || 0;
          console.log("Evaluated previous value:", evaluatedPreviousValue);
          console.log(
            "evaluatedCurrentValue",
            evaluatedCurrentValue,
            evaluatedPreviousValue
          );

          const statusValue =
            evaluatedPreviousValue !== 0
              ? ((evaluatedCurrentValue - evaluatedPreviousValue) /
                  evaluatedPreviousValue) *
                100
              : 0;

          console.log(
            "evaluatedCurrentValue",
            evaluatedCurrentValue,
            evaluatedPreviousValue,
            statusValue
          );

          return {
            dvName: row.dvName,
            statusValue: statusValue,
            formula: row.soft_tag_id__formula,
          };
        } else {
          const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;

          return {
            dvName: row.dvName,
            statusValue: isFinite(avgCal) ? avgCal : 0.0,
          };
        }
      });

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  useEffect(() => {
    if (realTimeData.length) {
      const topicArray = realTimeData.map(
        (e) => "Hikar/DeviceUID/" + e.device_uid
      );
      // console.log("topicArray", topicArray);
      setTopics(topicArray);
    }
  }, [realTimeData]);
  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  useEffect(() => {
    handleRealTimeData(); // Call it immediately on mount

    // Set up interval to call handleRealTimeData every 1 minute
    const intervalId = setInterval(() => {
      handleRealTimeData();
    }, 60000); // 60000 milliseconds = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleRealTimeData = async () => {
    const params = {
      plant_id: param.id,
      weather_tags: [
        10900, 10901, 10902, 10903, 10904, 10905, 10906, 10907, 10908,
      ],
    };
    try {
      const resp = await getPlantProcessDashboardWeatherPeakData(params);
      if (resp.status === 200 || resp.status === 201) {
        const tagsData = resp.data.payload;
        setRealTimeData(tagsData);

        const tagDataList = tagsData.map((e) => {
          const obj = {
            dvName: e.device_tag || "-",
            tagName: e.tag_name,
            machineUID: e.device_uid,
            is_soft_tag: e.is_soft_tag || false,
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        // Combine default and calculated tags if needed
        setTagListDataGroupAndList(tagDataList);

        // Evaluate formula if it’s a soft tag
        const evaluateSoftTagValue = (formula, tags) => {
          try {
            const tagPattern = /[A-Za-z0-9_]+/g;
            const tagMatches = formula.match(tagPattern) || [];
            let evaluatedFormula = formula;

            // Replace tags in formula with current values from tags list
            tagMatches.forEach((tag) => {
              const foundTag = tags.find((t) => t.dvName === tag);
              const tagValue = foundTag ? parseFloat(foundTag.curValue) : 0;
              evaluatedFormula = evaluatedFormula.replace(tag, tagValue);
            });

            return eval(evaluatedFormula);
          } catch {
            return 0; // Default to 0 on error
          }
        };

        const tagObj = tagsData.map((e) => {
          let curValue = 0;
          let prevValue = 0;

          if (e.is_soft_tag && e.soft_tag_id__formula) {
            curValue = evaluateSoftTagValue(
              e.soft_tag_id__formula,
              mqttTagPreviousvalue
            );
            prevValue = curValue;
          }

          const obj = {
            is_soft_tag: e.is_soft_tag,
            dvName: e.device_tag || "-",
            prevValue,
            curValue,
            dateAndTime: getTagDateAndTime(),
            soft_tag_id__formula: e.soft_tag_id__formula,
          };
          return obj;
        });

        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("Error in handleRealTimeData:", error);
    }
  };

  const handleDateCo2Time = (event) => {
    setCo2Time(event.target.value);
  };

  const handleDateCostTime = (event) => {
    setCostTime(event.target.value);
  };
  const handleDateInverterTime = (event) => {
    setInverterTime(event.target.value);
  };

  const handleDateWeatherTime = (event) => {
    setWeatherTime(event.target.value);
  };
  const handleDateWeatherValue = (event) => {
    setWeatherValue(event.target.value);
  };

  const handleDateGenerationTime = (event) => {
    setGenerationTime(event.target.value);
  };
  const handleDateGenerationValue = (event) => {
    setGenerationValue(event.target.value);
  };

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  useEffect(() => {
    handlePlantPeakData();
  }, [dateRange]);

  const handlePlantPeakData = async () => {
    const params = {
      plant_id: param.id,
      peak_tags: 10900,
      peak_tilt_tags: 10904,
      time_period: dateRange,
    };
    try {
      const resp = await getPlantProcessDashboardPlantPeak(params);
      if (resp.status == 200 || resp.status == 201) {
        setPlantPeakData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handleGenerationGraphData();
    handleGenerationBoxData();
  }, [generationTime, generationValue]);

  const handleGenerationGraphData = async () => {
    const params = {
      plant_id: param.id,
      time_period: generationTime,
    };
    if (generationValue == 1) {
      params["generation_tag"] = 10913;
      params["graph_tag"] = 1;
    } else if (generationValue == 2) {
      params["pr_tag"] = 10913;
      params["graph_tag"] = 2;
    } else {
      params["cuf_tag"] = 10913;
      params["graph_tag"] = 3;
    }

    try {
      const resp = await getHikemmREPlantDashboardGeneration(params);
      if (resp.status == 200 || resp.status == 201) {
        setGenerationData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleGenerationBoxData = async () => {
    const params = {
      plant_id: param.id,
      generation_tag: 10913,
      pr_tag: 10913,
      cuf_tag: 10913,
      time_period: generationTime,
    };
    try {
      const resp = await getHikemmREPlantDashboardGenerationBox(params);
      if (resp.status == 200 || resp.status == 201) {
        setGenartionBoxData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesumit", error);
    }
  };

  useEffect(() => {
    handleWeatherGraphData();
  }, [weatherTime, weatherValue]);

  const handleWeatherGraphData = async () => {
    const params = {
      plant_id: param.id,
      gti_tag: 10900,
      ghi_tag: 10901,
      wind_speed_tag: 10902,
      ambient_temperature_tag: 10903,
      module_temperature_tag: 10904,
      weather_graph: weatherValue,
      time_period: weatherTime,
    };
    try {
      const resp = await getPlantProcessDashboardWeatherGraphData(params);
      if (resp.status == 200 || resp.status == 201) {
        setWeatherGraphData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    handleInverterData();
  }, [inverterTime]);

  const handleInverterData = async () => {
    const params = {
      plant_id: param.id,
      tag_ids: [10934, 10935, 10936],
      time_period: inverterTime,
    };
    try {
      const resp = await getHikemmREPlantDashboardInverter(params);
      if (resp.status == 200 || resp.status == 201) {
        setInverterData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    handleCostData();
  }, [costTime]);

  const handleCostData = async () => {
    const params = {
      plant_id: param.id,
      cost_saving_tag: 10900,
      time_period: costTime,
    };
    try {
      const resp = await getPlantProcessDashboardCostSavingData(params);
      setCostData(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    handleCo2Data();
  }, [co2Time]);

  const handleCo2Data = async () => {
    const params = {
      plant_id: param.id,
      co2_tag: 10900,
      time_period: co2Time,
    };
    try {
      const resp = await getPlantProcessDashboardCo2Data(params);
      setCo2Data(resp.data.payload);
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="Bpcl-page-wrapper">
        {topics.length ? (
          <OverViewMachineMqttData
            topics={topics}
            tagListDataGroupAndList={tagListDataGroupAndList}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
          />
        ) : null}
   <BPCLGeneration
          generationData={generationData}
          generationTime={generationTime}
          generationValue={generationValue}
          handleDateGenerationTime={handleDateGenerationTime}
          handleDateGenerationValue={handleDateGenerationValue}
          generationBoxData={generationBoxData}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={realTimeData}
        />
        <BPCLAvailability
          plantPeakData={plantPeakData}
          handleDateRangeChange={handleDateRangeChange}
          dateRange={dateRange}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={realTimeData}
        />
     
        <BPCLWeathers
          handleDateWeatherTime={handleDateWeatherTime}
          handleDateWeatherValue={handleDateWeatherValue}
          weatherTime={weatherTime}
          weatherValue={weatherValue}
          weatherGraphData={weatherGraphData}
          sliderRealTimeTagData={sliderRealTimeTagData}
          realTimeData={realTimeData}
        />
        <BPCLInverters
          handleDateInverterTime={handleDateInverterTime}
          inverterTime={inverterTime}
          inverterData={inverterData}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          mqttTagPreviousvalue={mqttTagPreviousvalue}
          tagStatus1={tagStatus}
          sliderRealTimeTagData={sliderRealTimeTagData}
          weatherGraphData={realTimeData}
        />

        <BPCLCost
          costData={costData}
          handleDateCostTime={handleDateCostTime}
          costTime={costTime}
          handleDateCo2Time={handleDateCo2Time}
          co2Time={co2Time}
          co2Data={co2Data}
        />
        {/* <Grid container spacing={1} className="deshbord_BPCL sticky-container">
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="card_weather1"
              onClick={() =>
                navigate(
                  `/energy_trends/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              <Typography variant="h5" className="deshbord_bpcl">
                ENERGY TRENDS
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="card_weather1">
              <Typography
                variant="h5"
                className="deshbord_bpcl"
                onClick={() =>
                  navigate(
                    `/inverters/company/${param.companyId}/plant/${param.id}`
                  )
                }
              >
                INVERTERS
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              className="card_weather1"
              onClick={() =>
                navigate(
                  `/reports/company/${param.companyId}/plant/${param.id}`
                )
              }
            >
              <Typography variant="h5" className="deshbord_bpcl">
                REPORTS
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            onClick={() =>
              navigate(
                `/bpcl-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }
          >
            <div className="card_weather1">
              <Typography variant="h5" className="deshbord_bpcl">
                ANALYTICS
              </Typography>
            </div>
          </Grid>
        </Grid> */}
       
      </div>
      <div>
      <Grid container className="sticky-container" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <button className="bpcl-pro-dashboard-btns"   onClick={() =>
                navigate(
                  `/energy_trends/company/${param.companyId}/plant/${param.id}`
                )
              }>ENERGY TRENDS</button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <button className="bpcl-pro-dashboard-btns"  onClick={() =>
                  navigate(
                    `/inverters/company/${param.companyId}/plant/${param.id}`
                  )
                }>INVERTERS</button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <button className="bpcl-pro-dashboard-btns"  onClick={() =>
                navigate(
                  `/reports/company/${param.companyId}/plant/${param.id}`
                )
              }>REPORTS</button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <button className="bpcl-pro-dashboard-btns"  onClick={() =>
              navigate(
                `/bpcl-analytics/company/${param.companyId}/plant/${param.id}`
              )
            }>ANALYTICS</button>
          </Grid>
        </Grid>
        </div>
    </>
  );
};
export default BPCLProcessDashboard;
