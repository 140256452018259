import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Mqqtdata from '../../ProcessDashboard/Mqqtdata';
import { SoftTagFormula } from '../softTag/SoftTagFormula';

const RealTimeMqttData = ({
    getDevice,
    handelCubeChartLineData,
    handelCubeChartLineData1,
    handelCubeChartLineData2,
    handelCubeChartLineData3,
    selectTimeRange,
    setCombineChartData,
    setTagVMSData,
    setMqttTagPreviousvalue,
    setMqttTagData,
    mqttTagData
}) => {
    const [topics, setTopics] = useState("");
  
    const [minMaxvalue, setMinMaxvalue] = useState("");
    const [minMaxvalue1, setMinMaxvalue1] = useState("");
    const [minMaxvalue2, setMinMaxvalue2] = useState("");
    const [minMaxvalue3, setMinMaxvalue3] = useState("");



    const { selectTimeZoneProfile } = useSelector(state => state.userState);

    useEffect(() => {
        if (getDevice.length) {
            const topicArray = getDevice.map((row) => 'Hikar/DeviceUID/' + row?.device__uid);
            setTopics(topicArray);
        }
    }, [getDevice]);

    useEffect(() => {
        if (getDevice.length && mqttTagData && selectTimeRange === "live") {
            const mergedArray = [].concat(...Object.values(mqttTagData));

            const getTagTime = () => {
                return new Date().toLocaleTimeString('en-US', {
                    timeZone: selectTimeZoneProfile,
                    hour12: false,
                });
            };

            const filteredArray = mergedArray.filter((obj) => {
                const device = obj.device__uid;
                const deviceTag = obj.device_tag;
                return getDevice.some(
                    (item) => item.device__uid === device && item.device_tag === deviceTag
                );
            });

            setTagVMSData(filteredArray);

            setMqttTagPreviousvalue((prev) => {
                return prev.map((row) => {
                    const findTag = filteredArray.find(
                        (f) => f.device_tag === row.tagName
                    );
                    return {
                        tagName: findTag?.device_tag || "-",
                        prevValue: row.curValue,
                        curValue: findTag?.tagValue || 0,
                    };
                });
            });

            setCombineChartData((prev) => {
                const updatedChartData = getDevice.map((device, index) => {
                    if (device.softTag && device.softTagFormula) {
                        const tagValueMap = {};
                        mergedArray.forEach((obj) => {
                            if (obj.device_tag) {
                                tagValueMap[obj.device_tag] = obj.tagValue;
                            }
                        });
            
                        const { softTagNotGetTag, value } = SoftTagFormula(device.softTagFormula, tagValueMap);
            
                        if (softTagNotGetTag) {
                            return {
                                tagValue: value,
                                tagTime: getTagTime(),
                            };
                        }
                    }
            
                    const foundTag = filteredArray.find(obj => obj.device_tag === device.device_tag);
                    return foundTag
                        ? { tagValue: foundTag.tagValue, tagTime: getTagTime() }
                        : { tagValue: prev[index]?.[prev[index].length - 1]?.tagValue || 0, tagTime: getTagTime() };
                });
            
                const zeroIndx = [...prev];
            
                updatedChartData.forEach((data, idx) => {
                    if (zeroIndx[idx].length >= process.env.REACT_APP_GRAPH_LENGTH) {
                        zeroIndx[idx].shift(); // Remove oldest entry if exceeds max length
                    }
            
                    // Check if the last entry is the same as the new one
                    const lastEntry = zeroIndx[idx][zeroIndx[idx].length - 1];
                    if (lastEntry?.tagValue !== data.tagValue || lastEntry?.tagTime !== data.tagTime) {
                        zeroIndx[idx].push(data); // Push new data only if different
                    }
            
                    // Handle chart line data for each device
                    switch (idx) {
                        case 0:
                            handelCubeChartLineData({ ...data }, minMaxvalue);
                            break;
                        case 1:
                            handelCubeChartLineData1({ ...data }, minMaxvalue1);
                            break;
                        case 2:
                            handelCubeChartLineData2({ ...data }, minMaxvalue2);
                            break;
                        case 3:
                            handelCubeChartLineData3({ ...data }, minMaxvalue3);
                            break;
                        default:
                            break;
                    }
                });
            
                return zeroIndx;
            });
            
        }
    }, [mqttTagData]);

    return (
        <>
            {selectTimeRange === "live" && topics && (
                <Mqqtdata
                    overViewTagData={getDevice}
                    topics={topics}
                    setMqttTagData={setMqttTagData}
                />
            )}
        </>
    );
};

export default RealTimeMqttData;
