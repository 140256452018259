import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  mobileNoRe: null,
  MFA_UserId: "",
  company_logo: "",
  companyId: "",
  is_super_admin: false,
  is_admin: false,
  companyName: "",
  companyDescription: "",
  is_redirect: false,
  user_access: [],
  redirectHikPred: "",
  dashboard: "",
  user_country: "",
  user_country_name: "",
  user_country_code_name: "",
  user_country_code: "",
  user_country_currency: "",
  user_country_currency_name: "",
  user_country_currency_symbol: "",
  is_renewable_energy: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails(state, { payload }) {
      return { ...state, ...payload };
    },
    setUserProfiles(state, { payload }) {
      return { ...state, ...payload };
    },
    clearUserDetails(state) {
      return initialState;
    },
    setUserOtpAndEmail(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});

export const {
  setUserDetails,
  setUserProfiles,
  clearUserDetails,
  setUserOtpAndEmail,
} = userSlice.actions;

export default userSlice.reducer;
