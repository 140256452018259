import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function BarChartCostBPCL({ countData, activeSwitches }) {
  const chartRef = useRef(null);


  const getColorForPlant = (plantId) => {
    const activeSwitch = activeSwitches.find(
      (switchObj) => switchObj.id === plantId
    );
    return activeSwitch ? activeSwitch.color : "#9B64B7"; // Default color if not found
  };


  const dataWithColors = countData.map((plant) => ({
    y: Math.abs(plant.cost_saving_value),
    color: getColorForPlant(plant.plant_id), // Use color based on plant ID
  }));


  const categories = countData?.map((plant) => plant.plant_name);

  const options = {
    chart: {
      type: "column",
      height: 230,
      width: 230,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: false,
      },

      max: 4,
    },
    scrollbar: {
      enabled: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    series: [
      {
        name: "Generation",
        data: dataWithColors,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [countData, activeSwitches]); // Re-run effect when countData or activeSwitches change

  return (
    <div
      style={{
        width: "100%",
        height: "195px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
}
