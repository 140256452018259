import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { CompactTagValue } from "../../../../../../components/CompactValue/CompactValue";
HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const HighchartsGraphData = ({
    selectUnitData,
    combineChartData,
    selectTagData,
    averageValue,
    average
}) => {

    const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  console.log("averageValue",averageValue)

    const [chartOption, setChartOption] = useState({
        chart: {
          type: "spline",
        },
        title: {
          text: null, // Remove the title
        },
        xAxis: {
          categories: [],
          scrollbar: {
            enabled: true,
          },
          gridLineWidth: 1,
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        series: [],
        colors: selectTagData.length ? selectTagData.map((e) => e.color) : [],
        exporting: {
          enabled: true, // Disable exporting options
        },
        credits: {
          enabled: false, // Disable credits
        },
        tooltip: {
          shared: true,
          formatter: function () {
            let s = `${this.x}<br />`;
            this.points.forEach(point => {
              s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
            });
            return s;
          },
        },
      });


    const categoriesMaxLegth = combineChartData.reduce((maxIndex, currentArray, currentIndex, array) => {
        if (currentArray.length > array[maxIndex].length) {
            return currentIndex;
        } else {
            return maxIndex;
        }
    }, 0);

    const categories = combineChartData[categoriesMaxLegth]?.map((e) => e.tagTime) || [];

    const averageSeries = average ? [{
        name: 'Average',
        data: averageValue.map((e) =>  parseFloat(CompactTagValue(selectUnit, e))),
        color: 'green',
        dashStyle: 'ShortDash', // You can use other dash styles as well
    }] : [];

    const seriesForDevices = selectTagData.map((row, index) => {
        return {
            name: row.tagName,
            data:  (combineChartData[index] &&
              combineChartData[index].map((e) =>
                parseFloat(CompactTagValue(selectUnit, e.tagValue))
              )) ||
            [],
            color: row.color,
            dashStyle: "Solid",
            lineWidth: 1,
        };
    });

    const series = [...seriesForDevices, ...averageSeries];

    useEffect(() => {
        setChartOption({
          ...chartOption,
          xAxis: {
            ...chartOption.xAxis,
            categories: categories.concat([""]),
          },
          yAxis: {
            title: {
              text: selectUnitData,
            },
          },
          series: series,
        });
      }, [combineChartData, average]);


    return (
        <div style={{ height: "320px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOption} />
        </div>
    );
};

export default HighchartsGraphData;
