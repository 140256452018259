import {
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  Box,
  Card,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Switch from "react-switch";
import React, { useEffect, useState } from "react";
import { images } from "../../../config/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import {
  addDeviceDataAPI,
  deleteMachineData,
  deviceCount,
  gatewayCloudStatus,
  getDeviceData,
  getDeviceTypeData,
  updateMachineData,
} from "../service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import MachineExpandDevice from "./MachineExpandDevice";
import dayjs from "dayjs";

const GateWayExpand = ({
  open,
  getMachine,
  row,
  handleGetMachine,
  addMachineModal,
  updateGatewayModal,
  deleteGatewayModal,
  getTopCount,
  dataCount,
  handlegetGateway,
  accessManagementDevice,
  handleTostMsg,
  cloudMachineData,
  cloudMachineData1,
}) => {
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [updateMachineModal, setUpdateMachineModal] = useState(false);
  const [deleteMachineModal, setDeleteMachineModal] = useState(false);
  const [machineId, setMachineId] = useState("");
  const [deviceTypeData, setdeviceTypeData] = useState([]);
  const [renewableEnergy, setRenewableEnergy] = useState(false);
  const [isSolarEnergy, setIsSolarEnergy] = useState(false);
  const [isWindEnergy, setIsWindEnergy] = useState(false);

  console.log("renewableEnergy", renewableEnergy);
  const [openMachine, setOpenMachine] = useState({});
  const initialValue = {
    product: "",
    device_name: "",
    device_description: "",
    device_type: "",
  };

  const initialData = {
    uid: "",
    machine_name: "",
    machine_description: "",
    is_non_hikar_product: "",
    tag_config_count: "",
    is_csv_upload_enabled: "",
    is_frequency_time_enabled: "",
    data_store_frequency_time: "",
  };

  const [updateMachine, setUpdateMachine] = useState(initialData);
  const [addDeviceData, setAddDeviceData] = useState(initialValue);
  const [productID, setProductID] = useState(1);
  const [deviceID, setDeviceID] = useState("");
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const { companyId } = useSelector((state) => state.userState);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    setDropdownVisible(updateMachine.is_frequency_time_enabled);
  }, [updateMachine.is_frequency_time_enabled]);

  const navigate = useNavigate();
  console.log("updateMachine", updateMachine);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleUpdateMachineClick = (e, id) => {
    if (
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal
    ) {
      return true;
    }
    setMachineId(id);
    setUpdateMachine({
      uid: e.uid,
      machine_name: e.machine_name,
      machine_description: e.machine_description,
      is_non_hikar_product: e.is_non_hikar_product,
      tag_config_count: e.tag_config_count,
      is_csv_upload_enabled: e.is_csv_upload_enabled,
      is_frequency_time_enabled: e.is_frequency_time_enabled,
      data_store_frequency_time: e.data_store_frequency_time,
    });
    setUpdateMachineModal(true);
    setAddDeviceModal(false);
    setDeleteMachineModal(false);
  };

  const handleVmsaddDeviceCloseModal = () => {
    setAddDeviceModal(false);
    setUpdateMachineModal(false);
    setDeleteMachineModal(false);
  };

  const handleUpdateMachinedevice = (e) => {
    setUpdateMachine({ ...updateMachine, [e.target.name]: e.target.value });
  };
  // const handleRadioChange = (event) => {
  //   const value = event.target.value;

  //   setUpdateMachine((prevData) => ({
  //     ...prevData,
  //     is_csv_upload_enabled: value === "CSV",
  //     is_frequency_time_enabled: value === "Time",
  //   }));

  //   setDropdownVisible(value === "Time");
  // };
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setUpdateMachine((prevState) => ({
      ...prevState,
      is_csv_upload_enabled: value === "CSV",
      is_frequency_time_enabled: value === "Time",
      data_store_frequency_time:
        value === "Time" ? "30" : prevState.data_store_frequency_time,
    }));
    setDropdownVisible(value === "Time");
  };
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  
  // Function to convert seconds to "HH:MM:SS"
  const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };
  

  const handleDropdownChange = (event) => {
    const selectedSeconds = Number(event.target.value);

    // Convert seconds back to "HH:MM:SS"
    const formattedTime = secondsToTime(selectedSeconds);

    // Update the state with the formatted time
    setUpdateMachine((prevData) => ({
      ...prevData,
      data_store_frequency_time: formattedTime,
    }));
  };

  const handleUpdateMachine = async () => {
    if (updateMachine.uid === "") {
      notifyError("Please Enter Uid");
      return;
    }
    if (updateMachine.machine_name === "") {
      notifyError("Please Enter Machine Name");
      return;
    }
    if (updateMachine.machine_description === "") {
      notifyError("Please Enter Machine Description");
      return;
    }

    const params = {
      gateway: row.id,
      uid: updateMachine.uid,
      machine_name: updateMachine.machine_name,
      machine_description: updateMachine.machine_description,
      is_non_hikar_product: false,
      is_csv_upload_enabled: updateMachine.is_csv_upload_enabled,
      is_frequency_time_enabled: updateMachine.is_frequency_time_enabled,
      data_store_frequency_time: updateMachine.data_store_frequency_time,
    };
    try {
      const resp = await updateMachineData(machineId, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateMachineModal(false);
        handleGetMachine();
        getTopCount();
        // dataCount();
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  const handleRenewableEnergyChange = (event) => {
    const selectedEnergy = event.target.value;

    // Update the state based on the selected energy type
    if (selectedEnergy === "Solar Energy") {
      setRenewableEnergy(true);
      setIsSolarEnergy(true);
      setIsWindEnergy(false);
    } else if (selectedEnergy === "Wind Energy") {
      setRenewableEnergy(true);
      setIsSolarEnergy(false);
      setIsWindEnergy(true);
    } else {
      setRenewableEnergy(false);
      setIsSolarEnergy(false);
      setIsWindEnergy(false);
    }
  };

  const handleDeleteData = async () => {
    try {
      const resp = await deleteMachineData(machineId);
      if (resp.status == 200 || resp.status == 201) {
        setDeleteMachineModal(false);
        handleGetMachine();
        getTopCount();
        dataCount();
        handlegetGateway();
        notifySuccess("Machine deleted successfully");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setDeleteMachineModal(false);
    }
  };

  const handleSwitchToggle = (productId) => {
    setProductID(productId);
  };

  const handleChangeAddDevice = (e) => {
    setAddDeviceData({ ...addDeviceData, [e.target.name]: e.target.value });
  };

  const handleAddDeviceClick = async () => {
    if (addDeviceData.device_name === "") {
      notifyError("Please Enter Device Name");
      return;
    }
    if (addDeviceData.device_description === "") {
      notifyError("Please Enter Device Description");
      return;
    }
    if (productID == 1) {
      if (addDeviceData.device_type === "") {
        notifyError("Please Enter Device Type");
        return;
      }
    }
    const params = {
      machine: machineId,
      product: productID,
      device_name: addDeviceData.device_name,
      device_description: addDeviceData.device_description,
      company: companyId,
      is_renewable_energy: renewableEnergy,
      is_solar_energy: isSolarEnergy, // from state, true if Solar Energy selected
      is_wind_energy: isWindEnergy,
    };
    if (productID == 1) {
      params["device_type"] = addDeviceData.device_type;
    }

    try {
      const resp = await addDeviceDataAPI(params);
      if (resp.status == 200 || resp.status == 201) {
        setAddDeviceModal(false);
        handleGetMachine();
        handlegetGateway();
        getTopCount();
        dataCount();
        notifySuccess("Device added successfully");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };
  const handleClick = (id) => {
    if (
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal
    ) {
      return true;
    }
    setMachineId(id);
    setAddDeviceData(initialValue);
    setAddDeviceModal(true);
    setUpdateMachineModal(false);
  };

  useEffect(() => {
    if (addDeviceModal) {
      getdeviceTypeApi();
    }
  }, [addDeviceModal, renewableEnergy]);

  const getdeviceTypeApi = async () => {
    const resp = await getDeviceTypeData(renewableEnergy);
    if (resp.status == 200 || resp.status == 201) {
      setdeviceTypeData(resp.data.payload);
    }
    console.log("devicedata", resp);
  };

  const handleSettingClick = (id) => {
    if (
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal
    ) {
      return true;
    }
    navigate(`/deviceManagement/configure/machine/${id}`);
  };

  const handleOpenDeleteDevice = (id) => {
    if (
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal
    ) {
      return true;
    }
    setMachineId(id);
    setDeleteMachineModal(true);
    setUpdateMachineModal(false);
    setAddDeviceModal(false);
  };
  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };
  const selectedTimeInSeconds = timeToSeconds(updateMachine.data_store_frequency_time);

  return (
    <>
      <TableCell colSpan={6} className="insideDeviceTable">
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box className="insideProductionTable">
            <Table
              className="device-gatway-table"
              size="small"
              aria-label="purchases"
              style={{ borderRadius: "0px" }}
            >
              <TableHead>
                {getMachine?.map((e) => {
                  const cloudData = cloudMachineData.length
                    ? cloudMachineData.find((f) => f.id == e.id)?.["is_sub"] ||
                      false
                    : false;
                  const cloudStatusDate = cloudMachineData
                    ? dayjs(cloudMachineData.sub_time).format("DD/MM/YYYY")
                    : "";

                  const cloudStatusTime = cloudMachineData
                    ? dayjs(cloudMachineData.sub_time).format("HH:mm:ss")
                    : "";

                  return (
                    <>
                      <TableRow>
                        <TableCell style={{ width: "121px" }}>
                          {cloudData ? (
                            <img
                              src={images.cloudActiveImg}
                              alt=""
                              className="cloudActiveImg"
                              onMouseEnter={() => handleOpenModal(e.id)}
                              onMouseLeave={() => handleCloseModal(e.id)}
                            />
                          ) : (
                            <img
                              src={images.cloudImg}
                              alt=""
                              className="cloudActiveImg"
                              onMouseEnter={() => handleOpenModal(e.id)}
                              onMouseLeave={() => handleCloseModal(e.id)}
                            />
                          )}
                          {deviceLastSeenModal[e.id] && (
                            <Card className="clientManagement-updateModal-data top">
                              <Box className="clientMangement-updatedataWrap-data">
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    className="clientManagement deviceTabListText"
                                  >
                                    Last Seen
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    className="clientManagement deviceTabListText small"
                                  >
                                    {cloudStatusDate}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    className="clientManagement deviceTabListText small"
                                  >
                                    {cloudStatusTime}
                                  </Typography>
                                </div>
                              </Box>
                            </Card>
                          )}
                        </TableCell>
                        <TableCell>
                          <b>{e.machine_name}</b>
                        </TableCell>
                        <TableCell>
                          <b>{e.machine_description}</b>
                        </TableCell>
                        <TableCell>
                          <b>UID : {e.uid}</b>
                        </TableCell>
                        <TableCell style={{ width: "223px" }}>
                          {(accessManagementDevice?.is_viewer &&
                            accessManagementDevice?.is_editor) ||
                          (!accessManagementDevice?.is_viewer &&
                            accessManagementDevice?.is_editor) ? (
                            <Stack
                              direction="row"
                              className="deviceManagementTable-icon"
                            >
                              <img
                                src={images.editImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={() =>
                                  handleUpdateMachineClick(e, e.id)
                                }
                              />
                              <img
                                src={images.addImage}
                                style={{
                                  height: "23px",
                                  marginTop: "3px",
                                  cursor: "pointer",
                                }}
                                alt=""
                                onClick={() => handleClick(e.id)}
                              />
                              {e.device_count == 0 ? (
                                <img
                                  src={images.settingImg}
                                  alt=""
                                  className="cloudActiveImg"
                                  style={{
                                    pointerEvents: "none",
                                    opacity: "0.4",
                                  }}
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/deviceManagement/configure/machine/${e.id}`
                                  //   )
                                  // }

                                  onClick={() => handleSettingClick(e.id)}
                                />
                              ) : (
                                <img
                                  src={images.settingImg}
                                  alt=""
                                  className="cloudActiveImg"
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/deviceManagement/configure/machine/${e.id}`
                                  //   )
                                  // }

                                  onClick={() => handleSettingClick(e.id)}
                                />
                              )}

                              <img
                                src={images.deleteImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={() => handleOpenDeleteDevice(e.id)}
                              />

                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  setOpenMachine((prev) => ({
                                    ...prev,
                                    [e.id]: !prev[e.id],
                                  }))
                                }
                              >
                                {openMachine[e.id] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              className="deviceManagementTable-icon"
                            >
                              <img
                                src={images.editImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={handleTostMsg}
                              />
                              <img
                                src={images.addImage}
                                style={{
                                  height: "23px",
                                  marginTop: "3px",
                                  cursor: "pointer",
                                }}
                                alt=""
                                onClick={handleTostMsg}
                              />
                              {e.device_count == 0 ? (
                                <img
                                  src={images.settingImg}
                                  alt=""
                                  className="cloudActiveImg"
                                  style={{
                                    pointerEvents: "none",
                                    opacity: "0.4",
                                  }}
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/deviceManagement/configure/machine/${e.id}`
                                  //   )
                                  // }

                                  onClick={handleTostMsg}
                                />
                              ) : (
                                <img
                                  src={images.settingImg}
                                  alt=""
                                  className="cloudActiveImg"
                                  // onClick={() =>
                                  //   navigate(
                                  //     `/deviceManagement/configure/machine/${e.id}`
                                  //   )
                                  // }

                                  onClick={handleTostMsg}
                                />
                              )}

                              <img
                                src={images.deleteImg}
                                alt=""
                                className="cloudActiveImg"
                                onClick={handleTostMsg}
                              />

                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  setOpenMachine((prev) => ({
                                    ...prev,
                                    [e.id]: !prev[e.id],
                                  }))
                                }
                              >
                                {openMachine[e.id] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Stack>
                          )}
                        </TableCell>
                      </TableRow>
                      {openMachine[e.id] && (
                        <MachineExpandDevice
                          machineId={e.id}
                          dataCount={dataCount}
                          setDeviceID={setDeviceID}
                          deviceID={deviceID}
                          deviceTypeData={deviceTypeData}
                          setdeviceTypeData={setdeviceTypeData}
                          productID={productID}
                          companyId={companyId}
                          addDeviceModal={addDeviceModal}
                          updateMachineModal={updateMachineModal}
                          deleteMachineModal={deleteMachineModal}
                          addMachineModal={addMachineModal}
                          updateGatewayModal={updateGatewayModal}
                          deleteGatewayModal={deleteGatewayModal}
                          handleGetMachine={handleGetMachine}
                          getTopCount={getTopCount}
                          handlegetGateway={handlegetGateway}
                          accessManagementDevice={accessManagementDevice}
                          handleTostMsg={handleTostMsg}
                          renewableEnergy={renewableEnergy}
                        />
                      )}
                    </>
                  );
                })}
              </TableHead>
            </Table>
          </Box>
        </Collapse>

        {updateMachineModal && (
          <Card className="addDeviceModal-card">
            <Box className="addDeviceModal-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="addDeviceModal-title"
              >
                UPDATE MACHINE
              </Typography>
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={() => handleVmsaddDeviceCloseModal()}
              />
            </Box>
            <Box className="addDeviceModal-headerWrap">
              <Typography variant="body2" gutterBottom>
                Enter the UID of your device and name it individually.
                <br></br> You can find the UID/OR on the nameplate of the device
                <br></br> supplied by <b>HIKAR</b>
              </Typography>
            </Box>
            <Box className="addDeviceModal-innerWrap">
              <Box className="addDeviceModal-checkBoxWrap">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={updateMachine.is_non_hikar_product} />
                    }
                  />
                </FormGroup>
                <Typography
                  variant="body2"
                  gutterBottomk
                  className="addDeviceModal-text"
                >
                  This Device is not supplied by HIKAR, generate a virtual UID.
                </Typography>
              </Box>

              <Stack spacing={2} pt="10px">
                <TextField
                  id="filled-basic"
                  name="uid"
                  label="UID"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleUpdateMachinedevice}
                  value={updateMachine.uid}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={images.scanImage} alt="" />
                      </InputAdornment>
                    ),
                    disabled:
                      updateMachine.tag_config_count == 0 &&
                      updateMachine.is_non_hikar_product == false
                        ? false
                        : true,
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. 71f73dee-4596-48c8-bdof-7f1234d
                </Typography>
                <TextField
                  id="filled-basic"
                  name="machine_name"
                  label="Machine Name"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleUpdateMachinedevice}
                  value={updateMachine.machine_name}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="mt-1"
                >
                  e.g. Furnace 01
                </Typography>
                <TextField
                  id="filled-basic"
                  name="machine_description"
                  label="Machine Description"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleUpdateMachinedevice}
                  value={updateMachine.machine_description}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. Furnace 01 of Plant 1
                </Typography>
              </Stack>
              <div className="d-flex justify-content-between">
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={
                    updateMachine.is_csv_upload_enabled
                      ? "CSV"
                      : updateMachine.is_frequency_time_enabled
                      ? "Time"
                      : ""
                  }
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="CSV"
                    control={<Radio />}
                    label="CSV"
                  />
                  <FormControlLabel
                    value="Time"
                    control={<Radio />}
                    label="Time"
                  />
                </RadioGroup>
                {dropdownVisible && (
                  <div>
                    <label htmlFor="time-select" style={{ marginRight: "7px" }}>
                      Time
                    </label>
                    <select
                      id="time-select"
                      className="select-dropdown"
                      value={selectedTimeInSeconds}
                      onChange={handleDropdownChange}
                    >
                      <option value="30">30s</option>
                      <option value="60">1m</option>
                      <option value="90">1.5m</option>
                      <option value="120">2m</option>
                      <option value="150">2.5m</option>
                      <option value="180">3m</option>
                      <option value="210">3.5m</option>
                      <option value="240">4m</option>
                      <option value="270">4.5m</option>
                      <option value="300">5m</option>
                    </select>
                  </div>
                )}
              </div>

              <Box className="accountSecurity-cardButtons deviceManagement">
                <button className="addDeviceBtn" onClick={handleUpdateMachine}>
                  UPDATE MACHINE TO HIKAR.CLOUD
                </button>
              </Box>
            </Box>
          </Card>
        )}
        {deleteMachineModal && (
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <Box className="deleteConfirmation-btnWrap">
              <button
                className="deleteConfirmation-Yesbtn"
                onClick={handleDeleteData}
              >
                Yes
              </button>
              <button
                className="deleteConfirmation-Nobtn"
                onClick={() => setDeleteMachineModal(false)}
              >
                No
              </button>
            </Box>
          </Card>
        )}

        {addDeviceModal && (
          <Card className="addDeviceModal-card addDeviceModal-card-modal">
            <Box className="addDeviceModal-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="addDeviceModal-title"
              >
                ADD DEVICE
              </Typography>
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={handleVmsaddDeviceCloseModal}
              />
            </Box>

            <Box className="addDeviceModal-innerWrap">
              <Stack spacing={2} pt="10px">
                <h6 className="pt-3">
                  <b>Choose The Product</b>
                </h6>

                <div className="mt-1 d-flex">
                  <button
                    className="btn-product d-flex gap-2"
                    onClick={() => handleSwitchToggle(3)}
                  >
                    <p className="title-switch">SmartTag</p>
                    <Switch
                      className="deviceManagement-switch"
                      onChange={() => handleSwitchToggle(3)}
                      checked={productID === 3}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#1c407b"
                    />
                  </button>
                  <button
                    className="btn-product1 d-flex gap-2"
                    onClick={() => handleSwitchToggle(1)}
                  >
                    <p className="title-switch">HikEMM</p>
                    <Switch
                      className="deviceManagement-switch"
                      onChange={() => handleSwitchToggle(1)}
                      checked={productID === 1}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#1c407b"
                    />
                  </button>
                  <button
                    className="btn-product2 d-flex gap-2"
                    onClick={() => handleSwitchToggle(2)}
                  >
                    <p className="title-switch">PredHik</p>
                    <Switch
                      className="deviceManagement-switch"
                      onChange={() => handleSwitchToggle(2)}
                      checked={productID === 2}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                      onColor="#1c407b"
                    />
                  </button>
                </div>

                <TextField
                  id="device-name"
                  name="device_name"
                  label="Device Name"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleChangeAddDevice}
                  value={addDeviceData.device_name}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="mt-1"
                >
                  e.g. Furnace 01
                </Typography>
                <TextField
                  id="device-description"
                  name="device_description"
                  label="Device Description"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleChangeAddDevice}
                  value={addDeviceData.device_description}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. Furnace 01 of Plant 1
                </Typography>
                <FormControl
                  variant="filled"
                  sx={{ minWidth: 120 }}
                  className="customeTextField deviceManagement"
                >
                   {productID === 1 && (
                  <>
                    <InputLabel id="device-type-label">HikEMM - RE</InputLabel>
                    <Select
                      labelId="device-type-label"
                      id="device-type-select"
                      onChange={handleRenewableEnergyChange}
                    >
                      <MenuItem value="Solar Energy">Solar Energy</MenuItem>
                      <MenuItem value="Wind Energy">Wind Energy</MenuItem>
                    </Select>
                  </>
                   )}
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ minWidth: 120 }}
                  className="customeTextField deviceManagement"
                >
                  {productID === 1 && (
                    <>
                      <InputLabel id="device-type-label">
                        Select Device Type
                      </InputLabel>
                      <Select
                        labelId="device-type-label"
                        id="device-type-select"
                        name="device_type"
                        value={addDeviceData.device_type}
                        onChange={handleChangeAddDevice}
                      >
                        {deviceTypeData.map((e) => (
                          <MenuItem
                            key={e.device_type_id}
                            value={e.device_type_name}
                          >
                            {e.device_type_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </Stack>
              <Box className="accountSecurity-cardButtons deviceManagement">
                <button className="addDeviceBtn" onClick={handleAddDeviceClick}>
                  ADD DEVICE TO HIKAR.CLOUD
                </button>
              </Box>
            </Box>
          </Card>
        )}
      </TableCell>
    </>
  );
};

export default GateWayExpand;
