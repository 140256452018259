import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Plan from "./PlanVsActual";
import Ideal from "./IdealVsActual";
import Summary from "./Summary";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyticsData } from "./Slice/AdvanceAnalyticsSlice";
import { ProductionCOC } from "./Graph/ProductionCOC";
import { ProductionPOPChart } from "./Graph/ProductionGroupChartPOP";
import { ProductionPiechartPOP } from "./Graph/ProductionPieChartPOP";
import {
  analyticsProductionavailibityCocExcel,
  analyticsProductionavailibityPOPExcel,
  getAdvanceAnalyticsProductionAvailabilityCOCDataApi,
  getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi,
} from "./services";
import dayjs from "dayjs";
import { getDeviceSourceAPI } from "../Source/services";
import { ProductionPiechartPOPDownTime } from "./Graph/ProductionPOPdownTime";
import { ProductionPiechartCOCDownTime } from "./Graph/PieChartCOCDownTime";
import { images } from "../../../config";
import COCDatePicker from "../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../components/POPDatePicker/POPDatePicker";
import { ToastContainer, toast } from "react-toastify";
import { stubTrue } from "lodash";
import DeviceListShimmer from "../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";
import { ProductionPiechartCOC } from "./Graph/PieChartCOC";

const AnalyticsProduction = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [active, setActive] = useState("Consumer");
  const [deviceId, setDeviceId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [availabilityCOCBarData, setAvailabilityCOCBarData] = useState({});
  const [availabilityPOPBarData, setAvailabilityPOPBarData] = useState({});
  const [availabilityPOP1BarData, setAvailabilityPOP1BarData] = useState({});
  const [AvailabilityCOCPieChartData, setAvailabilityCOCPieChartData] =
    useState([]);
  const [AvailabilityPOPPieChartData, setAvailabilityPOPPieChartData] =
    useState([]);
  const [AvailabilityPOP1PieChartData, setAvailabilityPOP1PieChartData] =
    useState([]);
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);
  const [isPOPDeviceSelected, setPOPDeviceSelected] = useState(false);
  const [isShimmerCOC, setShimmerCOC] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [openEnergyModal, setOpenEnergyModal] = useState(false);

  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);

  const {
    analyticsDataId,
    analyticsDataDeviceColor,
    analyticsProductionPOPId,
    analyticsDataProductionPOPColor,
    productionStartDateCOC,
    productionEndDateCOC,
    productionStartDatePOP,
    productionEndDatePOP,
    productionStartDatePOP1,
    productionEndDatePOP1,
  } = useSelector((state) => state.analyticsDataState);
  const modalRef = useRef();

  const { selectTimeZoneProfile, is_renewable_energy } = useSelector(
    (state) => state.userState
  );

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const { user_access } = useSelector((state) => state.userState);

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const dateFormat = "YYYY-MM-DD";
  const startDateReFormat = productionStartDateCOC
    ? dayjs(productionStartDateCOC)
    : null;
  const endDateReFormat = productionEndDateCOC
    ? dayjs(productionEndDateCOC)
    : null;

  const startDatePOPFormat = productionStartDatePOP
    ? dayjs(productionStartDatePOP)
    : null;
  const endDatePOPFormat = productionEndDatePOP
    ? dayjs(productionEndDatePOP)
    : null;

  const startDatePOP1Format = productionStartDatePOP1
    ? dayjs(productionStartDatePOP1)
    : null;
  const endDatePOP1Format = productionEndDatePOP1
    ? dayjs(productionEndDatePOP1)
    : null;

  const formatStartDate = productionStartDateCOC
    ? dayjs(productionStartDateCOC).format(dateFormat)
    : "";

  const formatEndDate = productionEndDateCOC
    ? dayjs(productionEndDateCOC).format(dateFormat)
    : "";

  const formatstartDatePOP = productionStartDatePOP
    ? dayjs(productionStartDatePOP).format(dateFormat)
    : "";
  const formatEndDatePOP = productionEndDatePOP
    ? dayjs(productionEndDatePOP).format(dateFormat)
    : "";

  const formatstartDatePOP1 = productionStartDatePOP1
    ? dayjs(productionStartDatePOP1).format(dateFormat)
    : "";
  const formatEndDatePOP1 = productionEndDatePOP1
    ? dayjs(productionEndDatePOP1).format(dateFormat)
    : "";

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChange = () => {};

  const navigate = useNavigate();

  const param = useParams();

  // Check if any device is selected
  useEffect(() => {
    const selectedDevices = analyticsDataDeviceColor.filter(
      (stack) => stack.plantId === param.id
    );
    setIsDeviceSelected(selectedDevices.length > 0);
  }, [analyticsDataDeviceColor, param.id]);

  useEffect(() => {
    const selectedDevices = analyticsDataProductionPOPColor.filter(
      (stack) => stack.plantId === param.id
    );
    setPOPDeviceSelected(selectedDevices.length > 0);
  }, [analyticsDataProductionPOPColor, param.id]);

  useEffect(() => {
    getDeviceData();
  }, []);

  const getDeviceData = async () => {
    setisShimmerdevicelist(true);

    const params = {
      device_type: "Consumption",
      plant_id: Number(param.id),
      is_renewable_energy: is_renewable_energy,
    };
    try {
      const resp = await getDeviceSourceAPI(params);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setDeviceData(resp.data.payload.device);
        setTagData(resp.data.payload.tag);
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      console.log("error", error);
    }
  };

  const dispatch = useDispatch();

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = analyticsDataDeviceColor.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...analyticsDataDeviceColor];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAnalyticsData({
          analyticsDataDeviceColor: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setAnalyticsData({
          analyticsDataDeviceColor: [
            ...analyticsDataDeviceColor,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(analyticsDataId)) {
      setDeviceId([]);
      dispatch(setAnalyticsData({ analyticsDataId: [] }));
    }
    if (analyticsDataId.includes(id)) {
      // setDeviceId(analyticsDataId.filter((element) => element !== id));
      dispatch(
        setAnalyticsData({
          analyticsDataId: analyticsDataId.filter((element) => element !== id),
        })
      );
    } else {
      // setDeviceId([...analyticsDataId, id]);
      dispatch(setAnalyticsData({ analyticsDataId: [...analyticsDataId, id] }));
    }
  };

  const handleSelectPOPDeviceId = (id, index, event, row) => {
    const existingIndex = analyticsDataProductionPOPColor.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    const existingIndexDevice = analyticsProductionPOPId.findIndex(
      (color) => color.deviceID === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1 && existingIndexDevice !== -1) {
      // If the selected device already exists, remove it
      const updatedColors = [...analyticsDataProductionPOPColor];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setAnalyticsData({
          analyticsDataProductionPOPColor: updatedColors,
        })
      );

      // Unselect the device
      const updateDevice = [...analyticsProductionPOPId];
      updateDevice.splice(existingIndexDevice, 1);
      dispatch(setAnalyticsData({ analyticsProductionPOPId: updateDevice }));
    } else {
      // Remove any previously selected devices
      // dispatch(setAnalyticsData({ analyticsDataProductionPOPColor: [] }));
      const existingId = analyticsProductionPOPId.length
        ? analyticsProductionPOPId.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;

      if (existingId) {
        return true;
      }

      // Add the selected device to the state array
      dispatch(
        setAnalyticsData({
          analyticsDataProductionPOPColor: [
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(
        setAnalyticsData({
          analyticsProductionPOPId: [
            ...analyticsProductionPOPId,
            { plantId: param.id, deviceID: id },
          ],
        })
      );
    }
  };

  const getAdvanceAnalyticsProductionAvailabilityCOCApi = async () => {
    const findPlantDevice = analyticsDataDeviceColor.length
      ? analyticsDataDeviceColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];
    const paramReq = {
      device: findPlantDevice,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };

    try {
      setShimmerCOC(true);
      const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
        paramReq
      );

      if (resp.status === 200 || resp.status === 201) {
        setTimeout(() => {
          setShimmerCOC(false);
        }, "2000");

        setAvailabilityCOCBarData(resp.data.payload);
        console.log("respresp", resp);
      } else {
        console.error("Received unexpected status:", resp.status);
      }
    } catch (error) {
      setShimmerCOC(false);
      console.error("An error occurred:", error);
      // You can also set a state to indicate that an error occurred, if needed.
      // setErrorState(true);
    }
  };

  useEffect(() => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleAnalyticsPOPBarChart();
    } else {
      setAvailabilityPOPBarData([]);
    }
  }, [analyticsProductionPOPId, formatstartDatePOP, formatEndDatePOP]);

  useEffect(() => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleAnalyticsPOP1BarChart();
    } else {
      setAvailabilityPOP1BarData([]);
    }
  }, [analyticsProductionPOPId, formatstartDatePOP1, formatEndDatePOP1]);

  useEffect(() => {
    const findPlantDevice = analyticsDataDeviceColor.length
      ? analyticsDataDeviceColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    handleAnalyticsPOPpieChartData();
  }, [analyticsProductionPOPId, formatEndDatePOP, formatstartDatePOP]);

  useEffect(() => {
    handleAnalyticsPOP1pieChartData();
  }, [analyticsProductionPOPId, formatEndDatePOP1, formatstartDatePOP1]);

  const handleAnalyticsPOPBarChart = async () => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && formatstartDatePOP && formatEndDatePOP) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP,
        end_date: formatEndDatePOP,
      };
      try {
        setShimmerPOP(true);
        const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
          params
        );
        if (resp.data.success === true) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, "2000");

          setAvailabilityPOPBarData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };

  const handleAnalyticsPOP1BarChart = async () => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && formatstartDatePOP1 && formatEndDatePOP1) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP1,
        end_date: formatEndDatePOP1,
      };
      try {
        setShimmerPOP(true);
        const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
          params
        );
        if (resp.data.success === true) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, "2000");

          setAvailabilityPOP1BarData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };

  const handleAnalyticsPOPpieChartData = async () => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP && formatEndDatePOP) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP,
        end_date: formatEndDatePOP,
      };
      try {
        setShimmerPOP(true);
        const resp =
          await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
            params
          );
        if (resp.data.success == true) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, "2000");

          setAvailabilityPOPPieChartData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const handleAnalyticsPOP1pieChartData = async () => {
    const findPlantByDevice = analyticsProductionPOPId.length
      ? analyticsProductionPOPId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP1 && formatEndDatePOP1) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP1,
        end_date: formatEndDatePOP1,
      };
      try {
        setShimmerPOP(true);
        const resp =
          await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
            params
          );
        if (resp.data.success == true) {
          setTimeout(() => {
            setShimmerPOP(false);
          }, "2000");

          setAvailabilityPOP1PieChartData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const getAdvanceAnalyticsProductionAvailabilityCOCPieChartApi = async () => {
    const findPlantDevice = analyticsDataDeviceColor.length
      ? analyticsDataDeviceColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    const paramReq = {
      device: findPlantDevice,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };

    try {
      const resp =
        await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
          paramReq
        );

      if (resp.status === 200 || resp.status === 201) {
        setAvailabilityCOCPieChartData(resp.data.payload);
      } else {
        console.error("Received unexpected status:", resp.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // You can also set a state to indicate that an error occurred, if needed.
      // setErrorState(true);
    }
  };

  useEffect(() => {
    const findPlantDevice = analyticsDataDeviceColor.length
      ? analyticsDataDeviceColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    if (findPlantDevice.length && formatStartDate && formatEndDate) {
      getAdvanceAnalyticsProductionAvailabilityCOCApi();
      getAdvanceAnalyticsProductionAvailabilityCOCPieChartApi();
    }
  }, [analyticsDataId, formatStartDate, formatEndDate]);

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          productionStartDateCOC: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          productionStartDateCOC: newValue,
        })
      );
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDateCOC: newValue,
        })
      );
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDateCOC: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionStartDateCOC: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     productionStartDateCOC: newValue,
    //   })
    // );
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          productionEndDateCOC: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(
        setAnalyticsData({
          productionEndDateCOC: newValue,
        })
      );
    } else if (!startDateReFormat) {
      dispatch(
        setAnalyticsData({
          productionEndDateCOC: newValue,
        })
      );
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionEndDateCOC: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionEndDateCOC: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setAnalyticsData({
    //     productionEndDateCOC: newValue,
    //   })
    // );
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPFormat
      ? endDatePOPFormat.diff(newValue, "days")
        ? endDatePOPFormat.diff(newValue, "days") > 0
          ? endDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          productionStartDatePOP: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPFormat && newValue.isBefore(endDatePOPFormat)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          productionStartDatePOP: newValue,
        })
      );
    } else if (!endDatePOPFormat) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDatePOP: newValue,
        })
      );
    } else if (endDatePOPFormat && newValue.isSame(endDatePOPFormat, "day")) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDatePOP: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionStartDatePOP: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     productionStartDatePOP: newValue,
    //   })
    // );
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPFormat
      ? startDatePOPFormat.diff(newValue, "days")
        ? startDatePOPFormat.diff(newValue, "days") > 0
          ? startDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPFormat && newValue.isAfter(startDatePOPFormat)) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP: newValue,
        })
      );
    } else if (!startDatePOPFormat) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP: newValue,
        })
      );
    } else if (
      startDatePOPFormat &&
      newValue.isSame(startDatePOPFormat, "day")
    ) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionEndDatePOP: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionEndDatePOP: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    dispatch(
      setAnalyticsData({
        productionEndDatePOP: newValue,
      })
    );
  };
  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1Format
      ? endDatePOP1Format.diff(newValue, "days")
        ? endDatePOP1Format.diff(newValue, "days") > 0
          ? endDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setAnalyticsData({
          productionStartDatePOP1: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1Format && newValue.isBefore(endDatePOP1Format)) {
      // Start date is before end date
      dispatch(
        setAnalyticsData({
          productionStartDatePOP1: newValue,
        })
      );
    } else if (!endDatePOP1Format) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDatePOP1: newValue,
        })
      );
    } else if (!endDatePOP1Format) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionStartDatePOP1: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionStartDatePOP1: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setAnalyticsData({
    //     productionStartDatePOP1: newValue,
    //   })
    // );
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1Format
      ? startDatePOP1Format.diff(newValue, "days")
        ? startDatePOP1Format.diff(newValue, "days") > 0
          ? startDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP1: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1Format && newValue.isAfter(startDatePOP1Format)) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP1: newValue,
        })
      );
    } else if (!startDatePOP1Format) {
      dispatch(
        setAnalyticsData({
          productionEndDatePOP1: newValue,
        })
      );
    } else if (
      startDatePOP1Format &&
      newValue.isSame(startDatePOP1Format, "day")
    ) {
      // End date is not set yet
      dispatch(
        setAnalyticsData({
          productionEndDatePOP1: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setAnalyticsData({
          productionEndDatePOP1: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    dispatch(
      setAnalyticsData({
        productionEndDatePOP1: newValue,
      })
    );
  };

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const handleAvailibityProductionExcel = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      device: analyticsDataId,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };
    try {
      const resp = await analyticsProductionavailibityCocExcel(params);
      if (resp.data.success === true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  const handleAvailibityProductionPOPExcel = async () => {
    const params = {
      company: param.companyId,
      plant: param.id,
      device: [analyticsProductionPOPId[0]?.deviceID],
      start_date: formatstartDatePOP,
      end_date: formatEndDatePOP,
      start_date_1: formatstartDatePOP1,
      end_date_1: formatEndDatePOP1,
    };
    try {
      const resp = await analyticsProductionavailibityPOPExcel(params);
      if (resp.data.success === true) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };
  const handleOpenEnergyModal = () => {
    setOpenEnergyModal((prev) => {
      const newState = !prev;
      console.log("openEnergy", newState);
      return newState;
    });
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.filter-img-class')) {
      setOpenEnergyModal(false);
    }
  };
  

  useEffect(() => {
    if (openEnergyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEnergyModal]);

  return (
    <>
      {/*  */}
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          {activeClass == "Analytics" ? (
            <div
              onClick={() =>
                navigate(
                  `/analytics-configration/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Production ~ Availability</span>
            </div>
          ) : activeClass == "Reports" ? (
            <div
              onClick={() =>
                navigate(
                  `/analytics-configration/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
              style={{ pointerEvents: "none" }}
            >
              <ArrowBackIcon />
              <span>Production ~ Plan Vs Actual</span>
            </div>
          ) : activeClass == "Ideal" ? (
            <div
              onClick={() =>
                navigate(
                  `/analytics/company/${param.companyId}/plant/${param.id}`
                )
              }
              style={{ pointerEvents: "none" }}
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Production ~ Ideal Vs Actual</span>
            </div>
          ) : (
            <div
              onClick={() =>
                navigate(
                  `/analytics/company/${param.companyId}/plant/${param.id}`
                )
              }
              className="page-back-btn"
            >
              <ArrowBackIcon />
              <span>Production - Summary</span>
            </div>
          )}
          <div className="d-flex analytics-production-btn">
            <button
              className={`${
                activeClass == "Analytics"
                  ? "Analytics-tabs availabilty-tabs"
                  : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Analytics")}
            >
              Availability
            </button>
            <button
              className={`${
                activeClass == "Reports"
                  ? "Analytics-tabs availabilty-tabs"
                  : "Reports-tabs repo-tabs"
              }`}
              style={{ pointerEvents: "none" }}
              onClick={() => setActiveClass("Reports")}
            >
              Plan Vs Actual
            </button>
            <button
              className={`${
                activeClass == "Ideal"
                  ? "Analytics-tabs availabilty-tabs"
                  : "Reports-tabs repo-tabs"
              }`}
              style={{ pointerEvents: "none" }}
              onClick={() => setActiveClass("Ideal")}
            >
              Ideal Vs Actual
            </button>
            <button
              className={`${
                activeClass == "Summary"
                  ? "Analytics-tabs availabilty-tabs"
                  : "Reports-tabs repo-tabs"
              }`}
              style={{ pointerEvents: "none" }}
              onClick={() => setActiveClass("Summary")}
            >
              Summary
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn analysis-tabs-btn-analytics pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex Overview-btns">
                  <button
                    className={`${
                      active == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      active == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    onClick={() => setActive("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
                {active == "Consumer" ? (
                
                   <div
                   onClick={(e) => {
                     e.stopPropagation(); // Prevent the event from propagating to the document
                     handleOpenEnergyModal();
                   }}
                   className="filter-img-class"
                 >
                   <img
                     src={images.filterImg}
                     alt=""
                     style={{ height: "40px" }}
                   />
                   </div>
                ) : (
                  <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the event from propagating to the document
                    handleOpenEnergyModal();
                  }}
                  className="filter-img-class"
                >
                  <img
                    src={images.filterImg}
                    alt=""
                    style={{ height: "40px" }}
                  />
                  </div>
               
                )}
              </div>
              <div
                className="analytics-aggregation analytics-datepicker gap-2"
                style={{ justifyContent: "flex-end", width: "100%" }}
              >
                {active == "Consumer" ? (
                  <>
                    <COCDatePicker
                      startDateReFormat={startDateReFormat}
                      endDateReFormat={endDateReFormat}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </>
                ) : (
                  <>
                    <POPDatePicker
                      startDateReFormat={startDatePOPFormat}
                      endDateReFormat={endDatePOPFormat}
                      startDate1ReFormat={startDatePOP1Format}
                      endDate1ReFormat={endDatePOP1Format}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="pt-2 d-flex gap-3 consumption-grid-card">
              <Grid container spacing={2}>
                {active == "Consumer" ? (
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className="productionAvailibility-box consumer-box">
                      <Box className="consumer-text-disc mb-2">
                        <h6 className="Title">Title</h6>
                        <h6>Availability Charts</h6>
                      </Box>
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Description</h6>
                        <h6>Availability Charts - Consumer over Consumer</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div
                        className="furnace-grid"
                        style={{ height: "374px", overflow: "auto" }}
                      >
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectDeviceId(
                                                e.device,
                                                index,
                                                event,
                                                e
                                              )
                                            }
                                            name={switchColors[index]}
                                            checked={
                                              analyticsDataDeviceColor.length
                                                ? analyticsDataDeviceColor.find(
                                                    (f) =>
                                                      f.plantId == param.id &&
                                                      f.deviceId == e.device
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            // checked={analyticsDataId.includes(e.device)}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            checked={
                                              analyticsDataDeviceColor.length
                                                ? analyticsDataDeviceColor.find(
                                                    (f) =>
                                                      f.plantId == param.id &&
                                                      f.deviceId == e.device
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            // checked={analyticsDataId.includes(e.device)}
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                          onClick={handleAvailibityProductionExcel}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={3}>
                    <Box className="productionAvailibility-box consumer-box">
                      <Box className="consumer-text-disc mb-2">
                        <h6 className="Title">Title</h6>
                        <h6>Availability Charts</h6>
                      </Box>
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Description</h6>
                        <h6>Availability Charts - Period over Period</h6>
                      </Box>

                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div
                        className="furnace-grid"
                        style={{ height: "391px", overflow: "auto" }}
                      >
                        <Box className="Grid-text mt-2">
                          {!isShimmerdevicelist ? (
                            deviceData.length ? (
                              deviceData
                                .filter((e) =>
                                  e.device__device_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((e, index) => (
                                  <Grid
                                    item
                                    xs={12}
                                    key={e.device__device_name}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      style={{
                                        background: "#efeffd",
                                        width: "100%",
                                        marginBottom: "7px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={8}
                                        style={{
                                          padding: "7px 12px",
                                          borderLeft: `4px solid ${
                                            switchColors[
                                              index % switchColors.length
                                            ]
                                          }`,
                                        }}
                                      >
                                        <h6 className="mt-2">
                                          {e.device__device_name}
                                        </h6>
                                      </Grid>
                                      {(analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (!analyticsAccess?.is_viewer &&
                                        analyticsAccess?.is_editor) ||
                                      (analyticsAccess?.is_viewer &&
                                        !analyticsAccess?.is_editor) ? (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectPOPDeviceId(
                                                e.device,
                                                index,
                                                event,
                                                e
                                              )
                                            }
                                            name={switchColors[index]}
                                            // checked={analyticsProductionPOPId.includes(
                                            //   e.device
                                            // )}
                                            checked={
                                              analyticsProductionPOPId.length
                                                ? analyticsProductionPOPId.find(
                                                    (f) =>
                                                      f.deviceID == e.device &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={handleTostMsg}
                                            name={switchColors[index]}
                                            // checked={analyticsProductionPOPId.includes(
                                            //   e.device
                                            // )}
                                            checked={
                                              analyticsProductionPOPId.length
                                                ? analyticsProductionPOPId.find(
                                                    (f) =>
                                                      f.deviceID == e.device &&
                                                      f.plantId == param.id
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={3} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          style={{ marginTop: "46px" }}
                          onClick={handleAvailibityProductionPOPExcel}
                        >
                          EXPORT ENERGY DATA
                        </button>
                      </div>
                    </Box>
                  </Grid>
                )}
                {active == "Consumer" ? (
                  <Grid item xs={12} sm={12} md={9}>
                    <Box
                      className="consumerEnergyData-box"
                      style={{ minHeight: "715px" }}
                    >
                      <div style={{ minHeight: "322px" }}>
                        <div
                          className="chart-div"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>
                            <b>Pareto Chart - Consumer over Consumer</b>
                          </h6>
                          <div className="d-flex">
                            <img src={images.DownTime} alt="" height="20px" />{" "}
                            <h6
                              style={{
                                marginLeft: "10px",
                                fontWeight: 400,
                                fontSize: "14px",
                                marginTop: "2px",
                              }}
                            >
                              Down Time
                            </h6>
                          </div>
                        </div>

                        {isDeviceSelected ? (
                          <ProductionCOC
                            availabilityCOCBarData={availabilityCOCBarData}
                            shimmer={isShimmerCOC}
                          />
                        ) : (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="chart-div">
                        <h6>
                          <b>Pie Chart - Consumer over Consumer</b>
                        </h6>
                        <div className="d-flex position-relative justify-content-around production-dounght-chart">
                          {isDeviceSelected ? (
                            <>
                              <div>
                                <ProductionPiechartCOC
                                  AvailabilityCOCPieChartData={
                                    AvailabilityCOCPieChartData
                                  }
                                  shimmer={isShimmerCOC}
                                />
                                <div
                                  className="text-center mt-4"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Up Time
                                </div>
                              </div>
                              <div>
                                <ProductionPiechartCOCDownTime
                                  AvailabilityCOCPieChartData={
                                    AvailabilityCOCPieChartData
                                  }
                                  shimmer={isShimmerCOC}
                                />
                                <div
                                  className="text-center mt-4"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Down Time
                                </div>
                              </div>
                            </>
                          ) : (
                            <img
                              src={images.noDataFoundImg}
                              className="notFoundDataImg"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box
                      className="consumerEnergyData-box"
                      style={{ minHeight: "715px" }}
                    >
                      <div style={{ minHeight: "322px", position: "relative" }}>
                        <div
                          className="chart-div"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6>
                            <b>Bar Chart - Period over Period</b>
                          </h6>
                          <div className="d-flex">
                            <img src={images.DownTime} alt="" height="20px" />{" "}
                            <h6
                              style={{
                                marginLeft: "10px",
                                fontWeight: 400,
                                fontSize: "14px",
                                marginTop: "2px",
                              }}
                            >
                              Down Time
                            </h6>
                          </div>
                        </div>
                        {isPOPDeviceSelected ? (
                          <ProductionPOPChart
                            availabilityPOPBarData={availabilityPOPBarData}
                            availabilityPOP1BarData={availabilityPOP1BarData}
                            shimmer={isShimmerPOP}
                          />
                        ) : (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="position-relative chart-div">
                        <h6>
                          <b>Pie Chart - Period over Period</b>
                        </h6>
                        <div className="d-flex position-relative justify-content-around production-dounght-chart">
                          {isPOPDeviceSelected ? (
                            <>
                              <div>
                                <ProductionPiechartPOP
                                  AvailabilityPOPPieChartData={
                                    AvailabilityPOPPieChartData
                                  }
                                  AvailabilityPOP1PieChartData={
                                    AvailabilityPOP1PieChartData
                                  }
                                  shimmer={isShimmerPOP}
                                />
                                <div
                                  className="text-center mt-4"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Up Time
                                </div>
                              </div>
                              <div>
                                <ProductionPiechartPOPDownTime
                                  AvailabilityPOPPieChartData={
                                    AvailabilityPOPPieChartData
                                  }
                                  AvailabilityPOP1PieChartData={
                                    AvailabilityPOP1PieChartData
                                  }
                                  shimmer={isShimmerPOP}
                                />
                                <div
                                  className="text-center mt-4"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  Down Time
                                </div>
                              </div>
                            </>
                          ) : (
                            <img
                              src={images.noDataFoundImg}
                              className="notFoundDataImg"
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </>
        ) : activeClass == "Reports" ? (
          <Plan />
        ) : activeClass == "Ideal" ? (
          <Ideal />
        ) : (
          <Summary />
        )}
      </div>
      {openEnergyModal &&
        (active == "Consumer" ? (
          <>
            <div className="consumerCard" ref={modalRef}>
              <Box className="productionAvailibility-box consumer-card-box">
                <Box className="consumer-text-disc mb-2">
                  <h6 className="Title">Title</h6>
                  <h6>Availability Charts</h6>
                </Box>
                <Box className="consumer-text-disc">
                  <h6 className="Title">Description</h6>
                  <h6>Availability Charts - Consumer over Consumer</h6>
                </Box>

                <TextField
                  variant="outlined"
                  className="search-textfield"
                  placeholder="Search Device here"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div
                  className="furnace-grid"
                  style={{ height: "374px", overflow: "auto" }}
                >
                  <Box className="Grid-text mt-2">
                    {!isShimmerdevicelist ? (
                      deviceData.length ? (
                        deviceData
                          .filter((e) =>
                            e.device__device_name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((e, index) => (
                            <Grid item xs={12} key={e.device__device_name}>
                              <Grid
                                container
                                alignItems="center"
                                style={{
                                  background: "#efeffd",
                                  width: "100%",
                                  marginBottom: "7px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    padding: "7px 12px",
                                    borderLeft: `4px solid ${
                                      switchColors[index % switchColors.length]
                                    }`,
                                  }}
                                >
                                  <h6 className="mt-2">
                                    {e.device__device_name}
                                  </h6>
                                </Grid>
                                {(analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (!analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (analyticsAccess?.is_viewer &&
                                  !analyticsAccess?.is_editor) ? (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={(event) =>
                                        handleSelectDeviceId(
                                          e.device,
                                          index,
                                          event,
                                          e
                                        )
                                      }
                                      name={switchColors[index]}
                                      checked={
                                        analyticsDataDeviceColor.length
                                          ? analyticsDataDeviceColor.find(
                                              (f) =>
                                                f.plantId == param.id &&
                                                f.deviceId == e.device
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      // checked={analyticsDataId.includes(e.device)}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    item
                                    xs={4}
                                    textAlign="right"
                                    className="grid-switch-unit"
                                  >
                                    <Switch
                                      style={{ float: "right" }}
                                      onClick={handleTostMsg}
                                      name={switchColors[index]}
                                      checked={
                                        analyticsDataDeviceColor.length
                                          ? analyticsDataDeviceColor.find(
                                              (f) =>
                                                f.plantId == param.id &&
                                                f.deviceId == e.device
                                            )
                                            ? true
                                            : false
                                          : false
                                      }
                                      // checked={analyticsDataId.includes(e.device)}
                                      sx={{
                                        "& .MuiSwitch-thumb": {
                                          bgcolor: "#828282",
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#D9D9E5 !important",
                                        },
                                        "& .Mui-checked": {
                                          "& .MuiSwitch-thumb": {
                                            bgcolor:
                                              switchColors[
                                                index % switchColors.length
                                              ],
                                          },
                                          "& .MuiSwitch-track": {
                                            bgcolor: "#E2D9F2 !important",
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          ))
                      ) : (
                        []
                      )
                    ) : (
                      <DeviceListShimmer itemCount={3} />
                    )}
                  </Box>
                </div>

                <div className="mt-3">
                  <button
                    className="export-btn"
                    style={{ marginTop: "46px" }}
                    onClick={handleAvailibityProductionExcel}
                  >
                    EXPORT ENERGY DATA
                  </button>
                </div>
              </Box>
            </div>
          </>
        ) : (
          <div className="consumerCard" ref={modalRef}>
            <Box className="productionAvailibility-box consumer-card-box">
              <Box className="consumer-text-disc mb-2">
                <h6 className="Title">Title</h6>
                <h6>Availability Charts</h6>
              </Box>
              <Box className="consumer-text-disc">
                <h6 className="Title">Description</h6>
                <h6>Availability Charts - Period over Period</h6>
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search Device here"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />
              <div
                className="furnace-grid"
                style={{ height: "391px", overflow: "auto" }}
              >
                <Box className="Grid-text mt-2">
                  {!isShimmerdevicelist ? (
                    deviceData.length ? (
                      deviceData
                        .filter((e) =>
                          e.device__device_name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((e, index) => (
                          <Grid item xs={12} key={e.device__device_name}>
                            <Grid
                              container
                              alignItems="center"
                              style={{
                                background: "#efeffd",
                                width: "100%",
                                marginBottom: "7px",
                              }}
                            >
                              <Grid
                                item
                                xs={8}
                                style={{
                                  padding: "7px 12px",
                                  borderLeft: `4px solid ${
                                    switchColors[index % switchColors.length]
                                  }`,
                                }}
                              >
                                <h6 className="mt-2">
                                  {e.device__device_name}
                                </h6>
                              </Grid>
                              {(analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (!analyticsAccess?.is_viewer &&
                                analyticsAccess?.is_editor) ||
                              (analyticsAccess?.is_viewer &&
                                !analyticsAccess?.is_editor) ? (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={(event) =>
                                      handleSelectPOPDeviceId(
                                        e.device,
                                        index,
                                        event,
                                        e
                                      )
                                    }
                                    name={switchColors[index]}
                                    // checked={analyticsProductionPOPId.includes(
                                    //   e.device
                                    // )}
                                    checked={
                                      analyticsProductionPOPId.length
                                        ? analyticsProductionPOPId.find(
                                            (f) =>
                                              f.deviceID == e.device &&
                                              f.plantId == param.id
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={4}
                                  textAlign="right"
                                  className="grid-switch-unit"
                                >
                                  <Switch
                                    style={{ float: "right" }}
                                    onClick={handleTostMsg}
                                    name={switchColors[index]}
                                    // checked={analyticsProductionPOPId.includes(
                                    //   e.device
                                    // )}
                                    checked={
                                      analyticsProductionPOPId.length
                                        ? analyticsProductionPOPId.find(
                                            (f) =>
                                              f.deviceID == e.device &&
                                              f.plantId == param.id
                                          )
                                          ? true
                                          : false
                                        : false
                                    }
                                    sx={{
                                      "& .MuiSwitch-thumb": {
                                        bgcolor: "#828282",
                                      },
                                      "& .MuiSwitch-track": {
                                        bgcolor: "#D9D9E5 !important",
                                      },
                                      "& .Mui-checked": {
                                        "& .MuiSwitch-thumb": {
                                          bgcolor:
                                            switchColors[
                                              index % switchColors.length
                                            ],
                                        },
                                        "& .MuiSwitch-track": {
                                          bgcolor: "#E2D9F2 !important",
                                        },
                                      },
                                    }}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ))
                    ) : (
                      []
                    )
                  ) : (
                    <DeviceListShimmer itemCount={3} />
                  )}
                </Box>
              </div>

              <div className="mt-3">
                <button
                  className="export-btn"
                  style={{ marginTop: "46px" }}
                  onClick={handleAvailibityProductionPOPExcel}
                >
                  EXPORT ENERGY DATA
                </button>
              </div>
            </Box>
          </div>
        ))}
    </>
  );
};
export default AnalyticsProduction;
