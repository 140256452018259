import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { images } from "../../../config/images";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BarChart } from "./BarChart";
import { useSelector, useDispatch } from "react-redux";
import { setOverviewData } from "./Slice/OverViewAnalyticsSlice";
import { getOverviewAnalyticsEnergyChartData } from "./services";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import "../OverView/OverView.css";
import { toast } from "react-toastify";

const ZoomInChart = ({
  setShowOverviewchart,
  setShowZoomChart,
  startDate,
  endDate,
  deviceIdRedux,
  deviceUnitRedux,
  showTime,
  startTime,
  endTime,
  deviceColors,
  startDateReFormat,
  endDateReFormat,
  is_renewable_energy,
}) => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [zoomChartData, setZoomChartData] = useState([]);
  const [isShimmer, setShimmer] = useState(false);
  const [average, setAverage] = useState(false);
  // const [showTime, setShowTime] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [openDeviceId, setOpenDeviceId] = useState(null);

  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const {
  //   deviceIdRedux,
  //   deviceUnitRedux,
  //   deviceColors,
  //   startDateRe,
  //   endDateRe,
  //   showTime,
  // } = useSelector((state) => state.overViewState);

  const toggleShowAll = (id) => {
    setOpenDeviceId(openDeviceId === id ? null : id);
  };

  // const toggleDevice = (id) => {
  //   setOpenDeviceId(prevOpenDeviceId => prevOpenDeviceId === id ? null : id);
  // };

  // const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  // const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;

  // const dateFormat = "YYYY-MM-DD";
  // const timeFormat = "HH:mm:ss";

  // const startTimeFormatRep = startDateRe
  //   ? dayjs(startDateRe).format(timeFormat)
  //   : "";
  // const endTimeFormatRep = endDateRe ? dayjs(endDateRe).format(timeFormat) : "";
  // const startTimeFormat =
  //   startDateRe != null ? dayjs(startDateRe).format(dateFormat) : "";
  // const formatEndDate =
  //   endDateRe != null ? dayjs(endDateRe).format(dateFormat) : "";

  console.log("startTimeFormat", zoomChartData);

  const findPlantTagUnit = deviceUnitRedux.length
    ? deviceUnitRedux.filter((f) => f.plantId == param.id)
    : [];

  useEffect(() => {
    getOverviewAnalyticsEnergyChartApi();
  }, [startDate, endDate, showTime, startTime, endTime]);

  const getOverviewAnalyticsEnergyChartApi = async () => {
    const findPlantDevice = deviceIdRedux.length
      ? deviceIdRedux.filter((f) => f.plantId == param.id)
      : [];
    const findPlantUnit = deviceUnitRedux.length
      ? deviceUnitRedux.filter((f) => f.plantId == param.id)
      : [];

    console.log("findPlantUnit", findPlantUnit);
    const paramRequestData = {
      device_id: findPlantDevice.map((id) => id.deviceID),
      tag_unit:
        findPlantUnit && findPlantUnit.length > 0
          ? findPlantUnit[0]["tagUnit"]
          : null,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };

    if (showTime) {
      paramRequestData["start_date"] = `${startDate} ${startTime}`;
      paramRequestData["end_date"] = `${endDate} ${endTime}`;
    }

    try {
      setShimmer(true);
      const resp = await getOverviewAnalyticsEnergyChartData(paramRequestData);

      if (resp.status == 200) {
        setShimmer(false);
        setZoomChartData(resp.data.payload);
      }
      console.log("resp", resp);
    } catch (error) {
      setShimmer(false);
      console.log("error", error);
    }
  };

  const handleToggleTime = () => {
    // setShowTime((prevShowTime) => !prevShowTime);
    dispatch(setOverviewData({ showTime: !showTime }));
  };
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChangePage = () => {
    setShowZoomChart(false);
    setShowOverviewchart(true);
  };

  return (
    <>
      <Container maxWidth="xxl" className="mt-3 mb-5">
        <div className="page-header page-header-analytics">
          <div
            className="page-back-btn"
            // onClick={() =>
            //   navigate(
            //     `/overView-analytics/company/${param.companyId}/plant/${param.id}`
            //   )
            // }
            onClick={handleChangePage}
          >
            <ArrowBackIcon />
            <span>Overview - Analytics</span>
          </div>
        </div>
        <div style={{ marginTop: "36px" }}>
          <Box className="overview-zoom-box">
            <Grid container spacing={2}>
              <Grid item md={10} sm={6} xs={6}>
                <div
                  className="pt-3 zoom-bar-chart"
                  style={{ paddingLeft: "20px" }}
                >
                  <h6>
                    {is_renewable_energy == true ? (
                      <b>Energy Bar Chart - Generation over Generation</b>
                    ) : (
                      <b>Energy Bar Chart - Consumer over Consumer</b>
                    )}
                  </h6>
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={6}>
                <div className="d-flex gap-3 zoom-out-ico">
                  <div
                    style={{ marginTop: "6px" }}
                    onClick={() =>
                      navigate(
                        `/overView-analytics/company/${param.companyId}/plant/${param.id}`
                      )
                    }
                  >
                    <img src={images.zoomOut} />
                  </div>

                  <div className="d-flex avg-toggle">
                    <p className="average-toggle" style={{ marginTop: "8px" }}>
                      Average
                    </p>
                    <Switch
                      className="average-toggle-switch"
                      checked={average}
                      onChange={(e) => setAverage(e.target.checked)}
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#828282",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#D9D9E5 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#50C878",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#CEE6E1 !important",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="p-4">
              <BarChart
                average={average}
                zoomChartData={zoomChartData}
                startTimeFormat={startDate}
                formatEndDate={endDate}
                isShimmer={isShimmer}
                deviceIdRedux={deviceIdRedux}
              />
            </div>
            <div
              style={{ height: "81px", background: "#F5F5F5" }}
              className="zoom-partition-div"
            >
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="zoom-unit-title"
              >
                <div style={{ marginBottom: "20px" }} className="energy-div">
                  <h6 style={{ marginLeft: "20px", marginTop: "30px" }}>
                    <b>
                      Energy [
                      {findPlantTagUnit.length
                        ? findPlantTagUnit[0]["tagUnit"]
                        : ""}
                      ]
                    </b>
                  </h6>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  className="toggle-avg"
                >
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={<Switch color="primary" checked={showTime} />}
                      label="Time"
                      labelPlacement="top"
                      className="time-toggle"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>
                  {showTime ? (
                    <>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Start Date"
                            className="custom-datepicker"
                            value={startDate}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDateRe: newValue })
                              )
                            }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="End Date"
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            value={endDateReFormat}
                            onChange={(newValue) =>
                              dispatch(setOverviewData({ endDateRe: newValue }))
                            }
                            className="custom-datepicker"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Start Date"
                            value={startDateReFormat}
                            onChange={(newValue) =>
                              dispatch(
                                setOverviewData({ startDateRe: newValue })
                              )
                            }
                            disableFuture
                            // onChange={(newValue) =>
                            //   dispatch(
                            //     setOverviewData({ startDateRe: newValue })
                            //   )
                            // }
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          marginTop: "14px",
                          marginRight: "9px",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="End Date"
                            value={endDateReFormat}
                            onChange={(newValue) =>
                              dispatch(setOverviewData({ endDateRe: newValue }))
                            }
                            className="custom-datepicker"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="" style={{ maxHeight: "239px", overflowY: "auto" }}>
              <Table className="overview-table overview-table1 zoominbarchart-table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "22%" }}></TableCell>
                    <TableCell>Min</TableCell>
                    <TableCell>Max</TableCell>
                    <TableCell>Mean</TableCell>
                    <TableCell>Std Dev</TableCell>
                    <TableCell>All Time Min</TableCell>
                    <TableCell>All Time Max</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zoomChartData.length
                    ? zoomChartData.map((row, index) => {
                        const findColor = deviceIdRedux.find(
                          (f) => f.deviceID == row.device_id
                        );
                        console.log("roww", row);
                        return (
                          <TableRow>
                            <TableCell className="zoomchart-td">
                              <Grid item xs={12} key={row.device_name}>
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    // background: "#efeffd",
                                    width: "100%",
                                    // marginBottom: px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    style={{
                                      padding: "7px 12px",
                                      borderLeft: `4px solid ${
                                        findColor ? findColor.color : ""
                                      }`,
                                    }}
                                  >
                                    <h6 className="mt-2">{row.device_name}</h6>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              {Number(row.min_value).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {Number(row.max_value).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {typeof row.mean_value === "number"
                                ? row.mean_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {typeof row.std_dev_value === "number"
                                ? row.std_dev_value.toFixed(2)
                                : 0}
                            </TableCell>
                            <TableCell>
                              {Number(row.all_time_min_value).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {Number(row.all_time_max_value).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : []}
                </TableBody>
              </Table>
            </div>
          </Box>

          {/* =============== ZoomInEnergyBarChart - responsive design =============== */}

          {/* <Container maxWidth="xl" className="mt-3 mb-5"> */}
          <Grid container spacing={2} className="mt-2 mb-5">
            {zoomChartData.length
              ? zoomChartData.map((row, index) => {
                  const findColor = deviceColors.find(
                    (f) => f.deviceId == row.device_id
                  );
                  const isOpen = openDeviceId === row.device_id;

                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <div className="overview-details">
                        <Grid
                          container
                          alignItems="center"
                          style={{ width: "100%" }}
                        >
                          <Grid
                            item
                            xs={8}
                            style={{
                              padding: "7px 12px",
                              borderLeft: `4px solid ${
                                findColor ? findColor.color : ""
                              }`,
                            }}
                          >
                            <h6 className="mt-2">{row.device_name}</h6>
                          </Grid>
                        </Grid>
                        <div className="overview-details-wrap">
                          <div>
                            <h4>Min:</h4>
                          </div>
                          <div>
                            <h6>{Number(row.min_value).toFixed(2)}</h6>
                          </div>
                        </div>
                        <div className="overview-details-wrap">
                          <div>
                            <h4>Max:</h4>
                          </div>
                          <div>
                            <h6>{Number(row.max_value).toFixed(2)}</h6>
                          </div>
                        </div>
                        <div className="overview-details-wrap">
                          <div>
                            <h4>Mean:</h4>
                          </div>
                          <div>
                            <h6>
                              {typeof row.mean_value === "number"
                                ? row.mean_value.toFixed(2)
                                : 0}
                            </h6>
                          </div>
                        </div>
                        {isOpen && (
                          <>
                            <div className="overview-details-wrap">
                              <div>
                                <h4>Std Dev:</h4>
                              </div>
                              <div>
                                <h6>
                                  {typeof row.std_dev_value === "number"
                                    ? row.std_dev_value.toFixed(2)
                                    : 0}
                                </h6>
                              </div>
                            </div>
                            <div className="overview-details-wrap">
                              <div>
                                <h4>All Time Min:</h4>
                              </div>
                              <div>
                                <h6>
                                  {Number(row.all_time_min_value).toFixed(2)}
                                </h6>
                              </div>
                            </div>
                            <div className="overview-details-wrap">
                              <div>
                                <h4>All Time Max:</h4>
                              </div>
                              <div>
                                <h6>
                                  {Number(row.all_time_max_value).toFixed(2)}
                                </h6>
                              </div>
                            </div>
                          </>
                        )}

                        <img
                          src={isOpen ? images.upIcon : images.downIcon}
                          alt="toggle visibility"
                          className={isOpen ? "up-arrow" : "down-arrow"}
                          onClick={() => toggleShowAll(row.device_id)}
                        />
                      </div>
                    </Grid>
                  );
                })
              : null}
          </Grid>
          {/* </Container> */}

          {/* <Container maxWidth="xl" className="mb-5">
              <Grid container spacing={2}>
                {zoomChartData.length ? zoomChartData.map((row, index) => {
                  const findColor = deviceColors.find(f => f.deviceId === row.device_id);
                  const isOpen = openDeviceId === row.device_id;
                  return (
                    <Grid item xs={12} sm={6} key={index}>
                      <div className="overview-details">
                        <Grid container alignItems="center" style={{ width: "100%" }}>
                          <Grid item xs={8} style={{ padding: "7px 12px", borderLeft: `4px solid ${findColor ? findColor.color : ""}` }}>
                            <h6 className="mt-2">{row.device_name}</h6>
                          </Grid>
                        </Grid>
                        <div className="overview-details-wrap">
                          <div><h4>Min:</h4></div>
                          <div><h6>{Number(row.min_value).toFixed(2)}</h6></div>
                        </div>
                        <div className="overview-details-wrap">
                          <div><h4>Max:</h4></div>
                          <div><h6>{Number(row.max_value).toFixed(2)}</h6></div>
                        </div>
                        <div className="overview-details-wrap">
                          <div><h4>Mean:</h4></div>
                          <div><h6>{typeof row.mean_value === "number" ? row.mean_value.toFixed(2) : 0}</h6></div>
                        </div>
                        {isOpen && (
                          <>
                            <div className="overview-details-wrap">
                              <div><h4>Std Dev:</h4></div>
                              <div><h6>{typeof row.std_dev_value === "number" ? row.std_dev_value.toFixed(2) : 0}</h6></div>
                            </div>
                            <div className="overview-details-wrap">
                              <div><h4>All Time Min:</h4></div>
                              <div><h6>{Number(row.all_time_min_value).toFixed(2)}</h6></div>
                            </div>
                            <div className="overview-details-wrap">
                              <div><h4>All Time Max:</h4></div>
                              <div><h6>{Number(row.all_time_max_value).toFixed(2)}</h6></div>
                            </div>
                          </>
                        )}
                        <img
                          src={isOpen ? images.upIcon : images.downIcon}
                          alt="toggle visibility"
                          className={isOpen ? "up-arrow" : "down-arrow"}
                          onClick={() => toggleDevice(row.device_id)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </Grid>
                  );
                }) : null}
              </Grid>
            </Container> */}
        </div>
      </Container>
    </>
  );
};

export default ZoomInChart;
