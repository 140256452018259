import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../../container/ProcessDashboard/ProcessDashboard.css";
import { images } from "../../../config";

export function GenerationDonutChart({ generationData }) {

    const chartRef = useRef(null);

    const options = {
        chart: {
            type: "pie",
            height: 250,
            width: 250,
            backgroundColor: "transparent",
        },
        title: {
            text: (generationData?.total_average)?.toFixed(2),
            align: 'center',
            verticalAlign: 'middle',
            y: 20,
            style: {
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#000',
            },
        },
        subtitle: {
            text: generationData?.tag_unit,
            align: 'center',
            verticalAlign: 'middle',
            y: 35, // Positioned below the title
            style: {
                fontSize: '12px',
                color: '#666',
            },
        },
        
        tooltip: {
            enabled: true,
            useHTML: true,
            formatter: function () {
                const total = this.series.data.reduce((acc, point) => acc + point.y, 0);
                const percentage = ((this.y / total) * 100).toFixed(2) + "%";
                const index = this.point.index;
                const deviceName = index === 0 ? "Current Total" : "Past Total";
                const deviceColor = index === 0 ? "#9A64B2" : "#D2D5DB";
                const originalValue = index === 0
                    ? generationData.current_average
                    : generationData.past_average;

                return `<div style="font-size: 12px">
                          <b>${deviceName}</b><br/>
                          <span style="color:${deviceColor}">\u25A0</span> ${originalValue} (${percentage})
                        </div>`;
            },
        },
        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: "Values",
                data: [
                    {
                        name: "Current Average",
                        y: generationData && generationData.current_average !== undefined ? Math.abs(generationData.current_average) : 0,
                        color: "#9A64B2",
                    },
                    {
                        name: "Past Average",
                        y: generationData && generationData.past_average !== undefined ? Math.abs(generationData.past_average) : 0,
                        color: "#D2D5DB",
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        if (chartRef.current && generationData) {
            chartRef.current.chart.update(options);
        }
    }, [generationData]);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "230px",
                transform: "translate(0px, 80px)"
            }}
            className="doughnut-chart1"
        >
            {generationData && generationData.current_average !== undefined || generationData.past_average !== undefined ? (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                />
            ) : (
                <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="No data found" />
            )}
              
   
        </div>
    );
}
