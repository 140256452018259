
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../../assets/hikar.png";
// import viewIcon from "../../../assets/images/viewIcon.png";
import { Grid } from "@mui/material";
// import { InputField } from "../../../components/InputField/InputField";
import { Button } from "../../../components/Button/button";
import { Card, InputAdornment } from "@material-ui/core";
// import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import "./Sign.css";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signInApi } from "../service";
import { setUserDetails } from "../slice/userSlice";
import Checkbox from "@mui/material/Checkbox";
import { images } from "../../../config/images";
import Cookies from "universal-cookie";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import { pbkdf2 } from "pbkdf2";
import { clearStore } from "../../../redux/ClearStore/clearStore";
import PrivacyPolicy from "../../../components/Privacy/Privacy";
import ReCAPTCHA from "react-google-recaptcha";

export default function SignIn({
  onNextClick,
  setMobileNo,
  setEmail,
  setMfaUserId,
}) {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const [hikarPopup,setHikarPopup] = useState(true)

  const { userName } = useSelector((state) => state.userLoginSlice);

  const dispatch = useDispatch();

  const cookies = new Cookies();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const [formvalues, setFormvalue] = React.useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [SignIncount, setSignIncount] = useState(0);
  const [Sitekey, setSitekey] = useState(
    "6LcvUFomAAAAAEjzQKoUaiz7gjxr6CVGjjxTUDMy"
  );
  const [isRecaptchaValid, setRecaptchaValid] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const {
    mobileNoRe
  } = useSelector((state) => state.userState);

  

  useEffect(() => {
    setFormvalue({
      email: cookies.get("username"),
      password: cookies.get("password"),
    });
  }, []);

  useMemo(() => {
    if (cookies.get("username") && cookies.get("password")) {
      setIsChecked(true);
    }
  }, [formvalues]);

  const password = formvalues.password;

  // const SignInHandle = () => {
  //   // handleSubmit();
  //   setSignIncount(SignIncount + 1);
  //   setLoading(true);

  //   setTimeout(async () => {
  //     if (formvalues.email) {
  //       if (formvalues.password) {
  //         if (recaptchaRef.current.getValue()) {
  //           handleSubmit();
  //         } else {
  //           setLoading(false);
  //           notify("Recapcha is required");
  //         }
  //       } else {
  //         setLoading(false);
  //         notify("Password is required");
  //       }
  //     } else {
  //       setLoading(false);
  //       notify("Email is required");
  //     }
  //   }, 2);
  // };

  // const handleSubmit = async () => {
  //   if (!formvalues.email) {
  //     notify("Please enter email id.");
  //     return true;
  //   }

  //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(formvalues.email)) {
  //     notify("Please enter valid email.");
  //     return true;
  //   }

  //   if (!formvalues.password) {
  //     notify("Please enter valid password.");
  //     return true;
  //   }
  //   setLoading(true);
  // };

  const handleSubmit = async () => {
    if (!formvalues.email) {
      notify("Please enter email id.");
      return true;
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(formvalues.email)) {
      notify("Please enter valid email.");
      return true;
    }

    if (!formvalues.password) {
      notify("Please enter valid password.");
      return true;
    }
    setLoading(true);
    function encryptPassword(password, salt, iterations) {
      return new Promise((resolve, reject) => {
        pbkdf2(password, salt, iterations, 32, 'sha256', (err, derivedKey) => {
          if (err) {
            reject(err);
          } else {
            const hash = derivedKey.toString('base64');
            const formattedHash = `pbkdf2_sha256$${iterations}$${salt}$${hash}`;
            resolve(formattedHash);
          }
        });
      });
    }
    // Example usage
    const salt = "wUSaq3zAOY9ddYZNFDYqIr";
    const iterations = 320000;

    const encryptedPassword = await encryptPassword(password, salt, iterations);

    const params = {
      email: formvalues.email,
      password: encryptedPassword,
    };
    try {
      const response = await signInApi(params);
      setLoading(false);
      if (
        response.data.message ==
        "Your 2-Step verification in enable, Please verify for login"
      ) {
        setMobileNo(response.data.payload.mobile_number);
        onNextClick("verify2Step");
        return true;
      }

      if (
        response.data.message ==
        "Your MFA verification in enable, Please verify for login"
      ) {
        setEmail(response.data.payload.email);
        setMfaUserId(response.data.payload.mfa_user_id);
        onNextClick("verifyMFA");
        return true;
      }

      if (response.data.message == "You login successfully") {
        // if (isChecked) {
        //   cookies.set("username", formvalues.email);
        //   cookies.set("password", formvalues.password);
        // } else {
        //   cookies.set("username", "");
        //   cookies.set("password", "");
        // }
        // console.log("response", response.data);

        if (userName != formvalues.email) {
          dispatch(clearStore());
        }

        dispatch(
          setUserDetails({
            firstName: response.data.payload.first_name,
            lastName: response.data.payload.last_name,
            email: response.data.payload.email,
            mobileNoRe: response.data.payload.mobile_number,
            token: response.data.payload.token,
            id: response.data.payload.id,
            MFA_UserId: response.data.payload.mfa_user_id,
            company_logo: response.data.payload.company_logo,
            companyId: response.data.payload.company_id,
            is_super_admin: response.data.payload.is_super_admin,
            is_admin: response.data.payload.is_admin,
            companyName: response.data.payload.company_name,
            companyDescription: response.data.payload.company_description,
            user_access:
              response.data.payload.user_access?.access_module_management || [],
            company_access:  response.data.payload.user_access?.company_access || [],
            plant_access: response.data.payload.user_access?.plant_access || [],
            user_country: response.data.payload.user_country,
            user_country_name: response.data.payload.user_country_name,
            user_country_code_name:
              response.data.payload.user_country_code_name,
            user_country_code: response.data.payload.user_country_code,
            user_country_currency: response.data.payload.user_country_currency,
            user_country_currency_name:
              response.data.payload.user_country_currency_name,
            user_country_currency_symbol:
              response.data.payload.user_country_currency_symbol,
            is_created_by_super_admin_user_company:
              response.data.payload.is_created_by_super_admin_user_company,
              is_renewable_energy :  response.data.payload.is_renewable_energy
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (error.response.data.message) {
        notify(error.response.data.message);
      } 
    }
  };


  const handleChange = (e) => {
    setFormvalue({ ...formvalues, [e.target.name]: e.target.value });
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleTermsClick = () => {
    window.location.replace("https://hikartech.com/terms-and-conditions/");
  };

  const Recaptchahandle = () => {
    if (recaptchaRef.current.getValue()) {
      setRecaptchaValid(true);
    }
  };

  return (
    <>
      <div></div>
      <Grid container className="main-container">
        <SliderAuth />
        <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
          <div className="pb-4">
            <img src={logo} alt="" className="signIn-logo" />
          </div>
          <h3 className="text-center singIn-title">Welcome</h3>
          <ValidatorForm>
            <TextField
              id="filled-basic"
              name="email"
              label="Email ID"
              value={formvalues.email}
              onChange={handleChange}
              variant="filled"
              style={{ width: "100%" }}
              InputLabelProps={{
                style: { color: "#011627" },
              }}
            />
            <TextField
              id="filled-basic"
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              variant="filled"
              value={formvalues.password}
              onChange={handleChange}
              style={{ width: "100%", marginTop: "20px" }}
              InputLabelProps={{
                style: { color: "#011627" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleTogglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? (
                      <img src={images.viewIcon} alt="" />
                    ) : (
                      <img src={images.eyecloseIcon} alt="" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <div className="SignIn-keepLogin-wrap">
              <div className="keepLogin-wrap">
                {/* <input
                                type="checkbox"
                                id="keepMeLogin"
                                className="signIncheckBox"
                                name="keepMeLogin"
                                value={isChecked}
                                checked={isChecked}
                                // onClick={handleCheckBox}
                            /> */}
                <Checkbox
                  className="signIncheckBox"
                  checked={isChecked}
                  onClick={() => setIsChecked(!isChecked)}
                />
                
                <label for="keepMeLogin" className="keepLogin-label login">
                  Keep me login
                </label>
              </div>
              <div
                className="forgotPassword-link"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </div>
            </div>
            {/* {SignIncount >= 0 ? (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={Sitekey}
                required
                className="ReCAPTCHA-R pb-3"
                onChange={Recaptchahandle}
              />
            ) : null} */}
            <div>
              <Button
                title="Login"
                classname={"custom-button"}
                // onClick={SignInHandle}
                onClick={handleSubmit}
              />
            </div>
          </ValidatorForm>

          <div className="SingIn-unlock d-flex flex-column">
            <div
              onClick={() =>
                window.location.assign("https://hikartech.com/unlock-account/")
              }
              style={{ cursor: "pointer" }}
            >
              Unlock account?
            </div>
            <div>Help</div>
          </div>

          <PrivacyPolicy />
        </Grid>
      </Grid>
      {isLoading && <Loader />}
    
    </>
  );
}
