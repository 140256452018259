import React from "react";
import { Box, Typography, Card, Select, MenuItem, Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../../components/BPCL/BPCLavailability/BPCLavailability.css";
import "bootstrap/dist/css/bootstrap.css";
import { BpclBarChart } from "../../Graph/BPCL/Availability-bar-chart";
import { AvailabilityDonutChart } from "../../Graph/BPCL/Availability-pie-chart";

const donutOptions1 = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
    },
  },
  title: {
    text: "72%",
    align: "center",
    verticalAlign: "middle",
    y: 20,
    style: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
    },
  },
  plotOptions: {
    pie: {
      innerSize: "70%",
      dataLabels: {
        enabled: false,
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Data",
      data: [
        { name: "Category A", y: 55 },
        { name: "Category B", y: 45 },
      ],
    },
  ],
};

const donutOptions2 = {
  chart: {
    type: "pie",
    options3d: {
      enabled: true,
      alpha: 45,
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "45%",
    align: "center",
    verticalAlign: "middle",
    y: 20,
    style: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#000",
    },
  },
  plotOptions: {
    pie: {
      innerSize: "70%",
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Vegetables",
      data: [
        { name: "Carrots", y: 25 },
        { name: "Peas", y: 30 },
        { name: "Potatoes", y: 45 },
      ],
    },
  ],
};

// Bar chart configuration

const BPCLAvailability = (props) => {
  const plantData = props.plantPeakData[0]?.data;
  const sliderRealTimeTagData = props.sliderRealTimeTagData

  console.log("sliderRealTimeTagData",sliderRealTimeTagData)

  // Extract x-axis categories (time) and y-axis data (tag_value)
  const categories = plantData?.map((item) =>
    item.MIN_KEY.split(" ")[1].substring(0, 5)
  ); // Only showing HH:MM
  const seriesData = plantData?.map((item) => item.tag_value);

  // Update the barOptions object with the dynamic data
  const barOptions = {
    chart: {
      type: "column",
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "kW",
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "kW",
        data: seriesData,
        color: "#007bff",
      },
    ],
    tooltip: {
      formatter: function () {
        const index = this.point.index;
        const dataPoint = plantData[index];
        return `
                <b>Time:</b> ${dataPoint.MIN_KEY}<br/>
                <b>Value:</b> ${dataPoint.tag_value} ${dataPoint.tag_unit}<br/>
                <b>Peak Time:</b> ${dataPoint.peak_time}<br/>
                <b>Peak Tilt Time:</b> ${dataPoint.peak_tilt_time || "N/A"}
            `;
      },
    },
  };

  console.log("plantpeak", props.plantPeakData[0]?.peak_tag_value_time);

  return (
    <Box>
      <Card className="availability-box">
        <Grid container spacing={4}>
          {/* Availability Section */}
          <Grid item xs={12} sm={12} md={5}  >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                className="typography_Growth"
                style={{ fontWeight: "600" }}
              >
                Availability
              </Typography>
               <Select
                value={props.dateRange}
                onChange={props.handleDateRangeChange}
                className="inpute-week"
              >
                <MenuItem value={1}>Today</MenuItem>
                <MenuItem value={2}>Week</MenuItem>
                <MenuItem value={3}>Month</MenuItem>
                <MenuItem value={4}>Quarter</MenuItem>
                <MenuItem value={5}>Year</MenuItem>
              </Select>
              
            </Box>

            <Grid container spacing={2} sx={{ marginTop: 2 }} style={{borderRight:"1px solid #f5efef"}}>
              <Grid item xs={12}  sm={12} md={6}>
               <AvailabilityDonutChart sliderRealTimeTagData={sliderRealTimeTagData[0]} text={"Plant"}/>
              </Grid>
              <Grid item xs={12}  sm={12} md={6} >
              <AvailabilityDonutChart sliderRealTimeTagData={sliderRealTimeTagData[1]} text={"Grid"}/>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={12} md={6} >
              <button className="BPCLavailability">
                  <h3 className="bpclPlant fw-bold fs-6 mx-3">Plant</h3>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <h3 className="plant_time"><b>{sliderRealTimeTagData[3]?.tagValue ? parseFloat(sliderRealTimeTagData[3]?.tagValue) : 0}</b></h3>
                    <span className="plant_hrs fs-6">hrs</span>
                  </div>
                </button>
              </Grid>
              <Grid item xs={12} md={6}>
              <button className="BPCLavailability">
                  <h3 className="bpclPlant fw-bold fs-6 mx-3">Grid</h3>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <h3 className="plant_time"><b>{sliderRealTimeTagData[4]?.tagValue ? parseFloat(sliderRealTimeTagData[4]?.tagValue) : 0}</b></h3>
                    <span className="plant_hrs fs-6">hrs</span>
                  </div>
                </button>
              </Grid>
            </Grid>
          
          </Grid>

          {/* Plant Growth Section */}
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <Typography variant="h6" className="typography_Growth mb-5">
                Plant Peak
              </Typography>
              <Select
                value={props.dateRange}
                onChange={props.handleDateRangeChange}
                className="inpute-week"
              >
                <MenuItem value={1}>Today</MenuItem>
                <MenuItem value={2}>Week</MenuItem>
                <MenuItem value={3}>Month</MenuItem>
                <MenuItem value={4}>Quarter</MenuItem>
                <MenuItem value={5}>Year</MenuItem>
              </Select>
            </Box>
            <BpclBarChart plantPeakData={props.plantPeakData[0]?.data}  className="mt-5"/>
            {/* <HighchartsReact highcharts={Highcharts} options={barOptions} /> */}
          </Grid>

          {/* Peak Time Section */}
          <Grid item xs={12} sm={12} md={3} className="mt-5">
            <Box sx={{ marginBottom: 2 }}>
              <h6 className="mt-5 mb-2 Peaktime">Peak Time</h6>
              <button className="bpclpeaktime">
                <h6 className="mt-2" style={{ color: "black",fontSize:"14px",fontWeight:400 }}>
                  From - To
                </h6>
                <h5 style={{ color: "black",fontWeight:600 }}>
                  {props.plantPeakData[0]?.peak_tag_value_time}
                </h5>
              </button>

              <h6 className="mt-4">Peak (kW)</h6>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak
                    </h3>
                    <div className="d-flex flex-availbility"
                    >
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.plantPeakData[0]?.peak_tag_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                      {props.plantPeakData[0]?.peak_tag_unit}
                      </span>
                    </div>
                  </button>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Peak
                    </h3>
                    <div className="d-flex flex-availbility"
                    >
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.plantPeakData[0]?.all_time_peak_tag_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                      {props.plantPeakData[0]?.peak_tag_unit}
                      </span>
                    </div>
                  </button>
                </Grid>
              </Grid>

              <h6 className="mt-4">Peak Tilt (W/m²)</h6>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      Peak Tilt
                    </h3>
                    <div className="d-flex flex-availbility"
                    >
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.plantPeakData[0]?.peak_tilt_tag_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                      {props.plantPeakData[0]?.peak_tilt_tag_unit}
                      </span>
                    </div>
                  </button>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <button className="BPCLavailability1">
                    <h3 className="bpclPlant" style={{ color: "black" }}>
                      All Time Tilt
                    </h3>
                    <div className="d-flex flex-availbility"
                    >
                      <h3 className="plant_time" style={{ color: "black" }}>
                        {props.plantPeakData[0]?.all_time_peak_tilt_tag_value}
                      </h3>
                      <span className="plant_hrs" style={{ color: "black" }}>
                      {props.plantPeakData[0]?.peak_tilt_tag_unit}
                      </span>
                    </div>
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default BPCLAvailability;
